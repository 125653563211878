import {
  setPublisher,
  setScreenSharePublisher,
  setAvailableAudioInputs,
  setAvailableVideoInputs,
  callActive,
  sessionPublish,
  sessionUnpublish,
  setPublisherStreamReady,
  selectedAudioInput,
  selectedVideoInput,
  selectedAudioOutput,
  setAudioInput,
  setVideoInput,
  setAvailableAudioOutputs,
  setAudioOutput,
} from '../store/session';
import OT, { Publisher } from '@opentok/client';
import { setFocusViewElement, setFocusViewInfo } from '../store/views';

export default class PublisherAdapter extends Publisher {
  private publisher: Publisher;
  private screenSharePublisher: Publisher;

  constructor() {
    super();
  }

  public initPublisher(
    replacementElementId: string | HTMLElement,
    properties?: OT.PublisherProperties,
    callback?: any
  ) {
    if (this.publisher) {
      this.publisher.destroy();
    }
    console.log(replacementElementId);
    callback = callback ? callback : (err) => {
      if (err) {
        console.error(err);
        return;
      }
      console.log(this.publisher);
      console.log(this.publisher?.stream?.name)
      setPublisher(this.publisher);
      /*
      This condition is here to ensure that when the user is in the preview lobby,
      their streams are not being published. Only once they press join does their
      stream publish.
      */
      if (!callActive.value) {
        this.initListeners();
        this.initInputDevices();
        this.initOutputDevices();
      } else {
        sessionPublish({
          publisher: this.publisher,
          callback: () => {
            setAudioInput(selectedAudioInput.value);
            setVideoInput(selectedVideoInput.value);
            setAudioOutput(selectedAudioOutput.value)
            setFocusViewElement({view: 'default', data: {}});
          }
        });
      }
    }
    this.publisher = OT.initPublisher(replacementElementId, properties, callback);
  }

  public initScreenSharePublisher(
    replacementElementId?: string | HTMLElement,
    properties?: OT.PublisherProperties,
    callback?: any
  ) {
    if (this.screenSharePublisher) {
      this.screenSharePublisher.destroy();
    }
    callback = callback ? callback : (err) => {
      if (err) {
        console.error(err);
        return;
      }
      setScreenSharePublisher(this.screenSharePublisher);
      if (callActive.value) {
        sessionPublish({
          publisher: this.screenSharePublisher,
          callback: () => {
            setFocusViewElement({view: 'screen', data: {}});
          }
        });
      }
    }
    properties = properties || { videoSource: 'screen', insertMode: 'append', height: '100%', width: '100%' };
    this.screenSharePublisher = OT.initPublisher(replacementElementId, properties, callback);
  }

  private initListeners() {
    this.publisher.on({
      streamCreated: event => {
        setPublisherStreamReady(true);
        console.log('publisher stream created');
      },
      streamDestroyed: event => {
        setPublisherStreamReady(false);
        console.log('publisher stream destroyed');
      }
    });
  }

  public unpublish(screenShare?: boolean) {
    if (screenShare) {
      sessionUnpublish(this.screenSharePublisher);
    } else {
      sessionUnpublish(this.publisher);
    }
  }

  private initInputDevices() {
    let audioInputs;
    let videoInputs;
    if (selectedAudioInput.value?.selected || selectedVideoInput.value?.selected) return;
    OT.getDevices((err, devices) => {
      audioInputs = devices.filter((device) => device.kind === 'audioInput');
      videoInputs = devices.filter((device) => device.kind === 'videoInput');

      audioInputs.forEach((device) => {
        if (device.label === this.publisher.getAudioSource().label) {
          device.selected = true;
        } else {
          device.selected = false;
        }
      });

      videoInputs.forEach((device) => {
        if (device.deviceId === this.publisher.getVideoSource().deviceId) {
          device.selected = true;
        } else {
          device.selected = false;
        }
      });

      setAvailableAudioInputs(audioInputs);
      setAvailableVideoInputs(videoInputs);
    });
  }

  private async initOutputDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let audioOutputs = devices.filter(device => device.kind ==='audiooutput');

    audioOutputs = audioOutputs.map(device => {
      device = device.toJSON();
      if (device.deviceId === 'default') {
        (device as any).selected = true;
      } else {
        (device as any).selected = false;
      }
      return device;
    });

    setAvailableAudioOutputs(audioOutputs);
  }
}