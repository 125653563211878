

import {
  defineComponent,
  ref,
  watch,
  onUnmounted,
  onMounted,
  provide
} from 'vue';
import {
  audioOutputs,
  selectedAudioOutput,
  setAudioOutput,
  publisher,
} from '../../../store/session';

// import SpeakerTest from '../../../utils/SpeakerTest';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'SpeakerPicker',
  components: {
    Dropdown
  },
  setup() {
    const audio = ref(null);
    const testSpeakerEl = ref(null);
    const noBrowserSupport = ref(false);

    watch(selectedAudioOutput, selectedAudioOutput => {
        if (noBrowserSupport.value) return;
        audio.value = selectedAudioOutput;
        testSpeakerEl.value.setSinkId(audio.value.deviceId);
    });

    watch(audioOutputs, (audioOutputs, old) => {
      if (!old && audioOutputs.length) {
        console.log(audioOutputs);
      }
    });

    const selectAudio = ({value}) => {
      console.log(value);
      setAudioOutput(value);
    };

    const testSpeaker = () => {
      testSpeakerEl.value.play();
    }

    onMounted(() => {
      const url = require('../../../../public/test-audio.mp3');
      testSpeakerEl.value = new Audio(url);
      if ((typeof (testSpeakerEl.value as any).sinkId === 'undefined')) {
        noBrowserSupport.value = true;
      } else {
        noBrowserSupport.value = false;
      }
    });

    onUnmounted(() => {
      testSpeakerEl.value = '';
    });

    provide('noBrowserSupport', noBrowserSupport);

    return {
      audioOutputs,
      audio,
      selectAudio,
      testSpeaker,
      noBrowserSupport,
    }
  }
})
