import { ModeratorState, ValidViews } from '../../utils/Types';

const STATE: ModeratorState = {
  agenda: [
    {
      id: 0,
      title:`General Video Chat`,
      subtitle:'Sounds, Game, Fun',
      done: false,
      active: true,
      view: ValidViews.DEFAULT,
      data: {}
    },
    {
      id: 1,
      title:`Ahhhhh!!! it's a monster`,
      subtitle:'Sounds, Game, Fun',
      done: false,
      active: false,
      view: ValidViews.VIDEO,
      data: {
        videoId: 'uP07louhhBY',
        callback: function(e) {console.log(e)},
      }
    },
    {
      id: 2,
      title:`"B" sounds ending in "ING"`,
      subtitle:'Sounds, Early learning, Activity',
      done: false,
      active: false,
      view: ValidViews.WHITEBOARD,
      data: {}
    },
    {
      id: 3,
      title:'Funny Monkey',
      subtitle:'Reengagement',
      done: false,
      active: false,
      view: ValidViews.TEST,
      data: {
        assetUrl: 'document.pdf'
      }
    },
    {
      id: 4,
      title:'Story Maze',
      subtitle:'Sounds, Game',
      done: false,
      active: false,
      view: ValidViews.DEFAULT,
      data: {}
    },
  ],
  media: [
    {
      id: 5,
      title: `One Little Finger`,
      subtitle: `NOODLES & PALS S1 E6`,
      src: require(`../../assets/media/noodles-and-pals.png`)
    },
    {
      id: 6,
      title: `First Words High Contr...`,
      subtitle: `NOODLE & PALS S1 E7`,
      src: require(`../../assets/media/spoon.png`)
    },
    {
      id: 7,
      title: `High/Low Pitch`,
      subtitle: `Eugene Speech Therapy`,
      src: require(`../../assets/media/eugene-speech-therapy.png`)
    },
    {
      id: 8,
      title: `Red Light, Green Light`,
      subtitle: `Super Simple Songs`,
      src: require(`../../assets/media/red-light-green-light.png`)
    },
    {
      id: 9,
      title: `Speech Agility Drills`,
      subtitle: `Speech`,
      src: require(`../../assets/media/speech-agility.png`)
    },
    {
      id: 10,
      title: `Funny Monkey`,
      subtitle: `Distraction`,
      src: require(`../../assets/media/funny-monkey.png`)
    }
  ],
  notes: [
    {
      id: 11,
      title: `Subjective`,
      content: ``,
    },
    {
      id: 12,
      title: `Objective`,
      content: ``,
    },
    {
      id: 13,
      title: `Assessment`,
      content: `Got a little hung up on the sh before “oo”`,
    },
    {
      id: 14,
      title: `Plan`,
      content: ``,
    },
  ]
};

export default STATE;