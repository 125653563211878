<script lang="ts">

import { defineComponent } from 'vue';

import Card from 'primevue/card';
import CameraPicker from './components/CameraPicker.vue';
import MicPicker from './components/MicPicker.vue';
import SpeakerPicker from './components/SpeakerPicker.vue';
import BrowserCheck from './components/BrowserCheck.vue';
// import BandwidthCheck from './components/BandwidthCheck.vue';

export default defineComponent({
  name: 'CallPreview',
  components: {
    Card,
    CameraPicker,
    MicPicker,
    SpeakerPicker,
    BrowserCheck,
    // BandwidthCheck,
  },
})
</script>
<template>
  <Card
    class="call-preview"
  >
    <template #title>
      Your Hardware
    </template>
    <template #content>
      <CameraPicker/>
      <MicPicker/>
      <SpeakerPicker/>
      <BrowserCheck/>
      <!-- <BandwidthCheck/> -->
    </template>
  </Card>
</template>
<style lang="scss" scoped>
.call-preview.p-card {
  position: absolute;
  width: 40vw;
  height: 745px;
  right: 3vw;
  top: 108px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 10px;
}
::v-deep(.p-card-body) {
  padding: 0 !important;
  .p-card-content {
    display: flex;
    flex-direction: column;
    padding: 1 rem !important;
  }
  .p-card-title {
    padding: 8px 10px;
    text-align: left;
    background: #6698FA;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #FFF;
    border-radius: 10px 10px 0 0;
  }
}
</style>