

import {
  defineComponent,
  ref,
  watch,
  onUnmounted,
} from 'vue';
import {
  audioInputs,
  selectedAudioInput,
  setAudioInput,
  publisher,
} from '../../../store/session';

import MicTest from '../../../utils/MicTest';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'MicPicker',
  components: {
    Dropdown
  },
  setup() {
    const audio = ref(null);
    const canvas = ref(null);
    let micLevel;

    watch(selectedAudioInput, (selectedAudioInput, old) => {
      audio.value = selectedAudioInput;
    });

    watch(publisher, (publisher, old) => {
      if (!old && publisher?.id) {
        micLevel = new MicTest({canvas: canvas.value});
        micLevel.init();
      }
    })

    const selectAudio = ({value}) => {
      setAudioInput(value);
    };

    onUnmounted(() => {
      micLevel.onDestroy();
    });

    return {
      canvas,
      audioInputs,
      audio,
      selectAudio,
    }
  }
})
