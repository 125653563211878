
import { defineComponent, ref } from 'vue';

import ElapsedTime from './components/ElapsedTime.vue';
import VideoSwitch from './components/VideoSwitch.vue';
import MicSwitch from './components/MicSwitch.vue';
import SpeakerSwitch from './components/SpeakerSwitch.vue';
import ToggleCall from './components/ToggleCall.vue';
import ScreenShareSwitch from './components/ScreenShareSwitch.vue';
import TabMenu from 'primevue/tabmenu';

import { connected, callActive } from '../../store/session';
import {
  setHostMenuOpen,
  setHostMenuSelection,
  hostMenuSelection,
  hostMenuOpen,
} from '../../store/views';

import { clinician } from '../../store/user';
import { HostMenuName } from '../../utils/Types';

export default defineComponent({
  name: 'CallHeader',
  components: {
    ElapsedTime,
    VideoSwitch,
    MicSwitch,
    SpeakerSwitch,
    ScreenShareSwitch,
    ToggleCall,
    TabMenu,
  },
  setup() {
    const menuItems = ref([
      { label: 'AGENDA', command: () => togglePanel(HostMenuName.AGENDA) },
      { label: 'NOTES', command: () => togglePanel(HostMenuName.NOTES) },
      {
        label: 'WHITEBOARD',
        command: () => togglePanel(HostMenuName.WHITEBOARD),
      },
    ]);

    const togglePanel = (menuName: HostMenuName) => {
      if (hostMenuOpen.value && menuName === hostMenuSelection.value) {
        console.log('close');
        setHostMenuOpen(false);
        return;
      }
      setHostMenuOpen(true);
      setHostMenuSelection(menuName);
    };

    return {
      connected,
      callActive,
      clinician,
      menuItems,
      hostMenuSelection,
      hostMenuOpen,
    };
  },
});
