import OT, { Session, Stream } from '@opentok/client';

import {
  removeStreamById,
  setSessionConnected,
  setSessionObject,
  addStreamById,
  setScreenShareStream
} from '../store/session';

import {
  addNewChatMessage,
} from '../store/chat';

import { clinician } from '../store/user';

import {
  addPendingAttendee,
  removePendingAttendee,
  applyAgendaUpdate,
  addConnectedAttendee
} from '../store/attendees';

import { setScreenShareActive, setVideoStatus, setWhiteboardStatus } from '../store/views';

import { AttendeeInfo, MessageType } from '../utils/Types';
import { markRaw } from 'vue';

export default class SessionAdapter extends Session {
  private session: Session;
  private token: string;
  private apiKey: string;

  constructor(apiKey, sessionId, token) {
    super();
    this.apiKey = apiKey;
    this.token = token;
    this.sessionId = sessionId;
  }

  private initListeners() {
    this.session.on({
      connectionCreated: event => {
        console.log(event);
        console.log('connection created');
      },
      connectionDestroyed: event => {
        console.log('connection destroyed');
      },
      sessionDisconnected: event => {
        if (event.reason == 'networkDisconnected') {
          alert('Your network connection terminated');
        }
      },
      streamCreated: event => {
        console.log(event.stream);
        if (event.stream.videoType === 'screen') {
          setScreenShareStream(event.stream);
          return;
        }
        this.getModeratorApproval(event.stream);
        console.log('stream created', event.stream.connection.connectionId);
      },
      streamDestroyed: event => {
        console.log('stream destroyed', event.stream.connection.connectionId)
        removeStreamById(event.stream.streamId);
      },
      streamPropertyChanged: event => {
        console.log(event);
      },
    });

    this.session.on('signal:agenda', applyAgendaUpdate);

    this.session.on('signal:video', (event: any) => {
      console.log('signal video status', event.data);
      setVideoStatus({playing: event.data, sendSignal: false});
    })

    this.session.on('signal:whiteboard', (event: any) => {
      if (clinician.value) return;
      setWhiteboardStatus({bool: !!JSON.parse(event.data), moderator: false});
    });

    this.session.on('signal:screen_share', (event: any) => {
      if (clinician.value) return;
      setScreenShareActive(JSON.parse(event.data));
    });

    this.session.on('signal:public_message', (event: any) => {
      const chatMessageObj = {
        from: event.from.connectionId,
        messageType: MessageType.Public,
        message: event.data,
        to: null
      };
      addNewChatMessage(chatMessageObj);
    });

    this.session.on('signal:private_message', (event: any) => {
      console.log(event);
      const chatMessageObj = {
        from: event.from.connectionId,
        messageType: MessageType.Private,
        message: event.data,
        to: this.session.connection.connectionId
      };
      addNewChatMessage(chatMessageObj);
    });

    this.session.on('signal:attendee_connected', (event: any) => {
      console.log(JSON.parse(event.data));
      addConnectedAttendee(JSON.parse(event.data));
    });

    this.session.on('signal:attendee_approved_init', (event: any) => {
      if (clinician.value) return;
      let {streams, attendees} = JSON.parse(event.data);
      console.log(attendees, streams);
      Object.keys(streams).forEach(key => {
        const attendeeConnectionId = streams[key].connection.connectionId;
        this.parseStream(streams[key], attendees[attendeeConnectionId])
      });
    });

    this.session.on('signal:attendee_approved', (event: any) => {
      if (clinician.value) return;
      let {streams, attendees} = JSON.parse(event.data);
      this.parseStream(streams, attendees);
      // console.log(streams, streams.streamId);
    });
  }

  private parseStream(stream: Stream, attendee: AttendeeInfo) {
    console.log(attendee);
    addStreamById({streamId: stream.streamId, attendee});
    removePendingAttendee(stream.streamId);
  }

  public initialize() {
    console.log(this.apiKey, this.sessionId);
    this.session = OT.initSession(this.apiKey, this.sessionId);
    // console.log(OT);

    this.session
      .connect(this.token, (error) => {
        if (error) {
          console.log(error);
          return;
        }
        this.initListeners();
        setSessionObject(markRaw(this.session));
        setSessionConnected();
      });
  }

  public disconnect() {
    this.session.disconnect();
  }

  private getModeratorApproval(stream) {
    addPendingAttendee(stream);
  }
}
