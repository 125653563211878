

import { 
  defineComponent, 
  ref,
  watchEffect,
} from 'vue';

import Menu from 'primevue/menu';

import { 
  setVideoState, 
  videoInputs, 
  setVideoInput, 
} from '../../../store/session';

export default defineComponent({
  name: 'VideoSwitch',
  components: {
    Menu
  },
  setup() {
    const enabled = ref(true);
    const menu = ref(null);
    const videoInputsMenuData = ref([]);

    const toggleVideo = () => {
      enabled.value = !enabled.value;
      setVideoState(enabled.value);
    };

    const toggleCameraList = toggle => {
      menu.value.toggle(toggle);
    };

    const selectVideo = videoInput => {
      setVideoInput(videoInput);
    };

    watchEffect(() => {
      videoInputsMenuData.value = [];
      videoInputs.value?.forEach(videoInput => {
        videoInputsMenuData.value.push({label: videoInput.label, icon: videoInput.selected ? 'pi pi-check' : null, commaned: () => selectVideo(videoInput)})
      });
    });

    return { 
      enabled, 
      menu,
      toggleVideo,
      toggleCameraList,
      videoInputsMenuData,
    }
  }
})
