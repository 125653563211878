import { getter } from './store';

export const attendees = getter('attendees', state => state.attendees);

export const attendeeCount = getter('attendeeCount', state => state.attendeeCount);

export const pendingAttendees = getter('pendingAttendees', state => state.pendingAttendees);

export const pendingAttendeeCount = getter('pendingAttendeeCount', state => state.pendingAttendeeCount);

export const connectedAttendees = getter('connectedAttendees', state => state.connectedAttendees);

export const connectedAttendeeCount = getter('connectedAttendeeCount', state => state.connectedAttendeeCount);

export const demoAttendees = getter('demoAttendees', state => {
  return [{index: 0, name: 'Dr. Zhivalgo', color: '#000000', streamId: '0'},{index: 1, name: 'Nico', color: '#73DFE7', streamId: null}, {index: 2, name: 'Lucas', color: '#FEB254', streamId: null}]
});