/**
 * Modules
 */
import Publisher from './Publisher.vue';
import Session from './Session.vue';
import CallHeader from './CallHeader';
import CallDrawer from './CallDrawer';
import Sidebar from 'primevue/sidebar';
import CallPreview from './CallPreview';

export default {
  install(app) {
    app.component('Publisher', Publisher);
    app.component('Session', Session);
    app.component('CallHeader', CallHeader);
    app.component('CallDrawer', CallDrawer);
    app.component('CallPreview', CallPreview);
    app.component('Sidebar', Sidebar);
  }
}