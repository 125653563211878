// @ts-check
import { Publisher } from '@opentok/client';
import { publisher } from '../store/session';

/**
 * MicTest Class
 * @category CallPreview
 */
class MicTest {
  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;
  WIDTH: number = 500;
  HEIGHT: number = 100;
  movingAvg: number = null;

  /**
   * @param {Object} props Props necessary to create visual mic test
   * @param {HTMLCanvasElement} props.canvas Canvas to visualize microphone activity
   */
  constructor(props) {
    /**
    * @property {HTMLCanvasElement} canvas Canvas element to visualize microphone activity
    */
    this.canvas = props.canvas;
    /**
    * @property {CanvasRenderingContext2D} canvasContext Canvas context to draw to
    */
    this.canvasContext = this.canvas.getContext("2d");
    this.initListener = this.initListener.bind(this);
    this.audioLevelUpdated = this.audioLevelUpdated.bind(this);
  }

  /**
   * Initialize the class
   */
  init() {
    this.WIDTH = this.canvas.width;
    this.HEIGHT = this.canvas.height;
    this.initListener();
  }

  /**
   * Initializes the listener
   */
  initListener() {
    publisher.value.on('audioLevelUpdated', this.audioLevelUpdated);
  }

  /**
   *
   * @typedef { import("@opentok/client").Publisher.audioLevelUpdated } AudioLevelUpdatedEvent
   */
  /**
   * Listener that updates the canvas with audio level values
   * @param { AudioLevelUpdatedEvent } event Event passed through the client publisher
   */
  audioLevelUpdated(event: OT.Event<'audioLevelUpdated', Publisher> & {
    audioLevel: number
  }) {
    const tagCount = this.WIDTH / 10;

    if (this.movingAvg === null || this.movingAvg <= event.audioLevel) {
      this.movingAvg = event.audioLevel;
    } else {
      this.movingAvg = 0.7 * this.movingAvg + 0.3 * event.audioLevel;
    }
    // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    let logLevel = (Math.log(this.movingAvg) / Math.LN10) / 1.5 + 1;
    logLevel = Math.min(Math.max(logLevel, 0), 1);

    const barWidth = Math.ceil(tagCount * logLevel) * 10;
    this.canvasContext.clearRect(0, 0, this.WIDTH, this.HEIGHT);
    this.canvasContext.fillStyle = 'rgba(235, 235, 240, 1)';
    this.canvasContext.fillRect(0, 0, this.WIDTH, this.HEIGHT);
    this.canvasContext.fillStyle = `rgba(88, 217, 125, 1)`;
    this.canvasContext.fillRect(0, 0, barWidth, this.HEIGHT);
  }
  /**
   * onDestroy method, removes audioLevelUpdated listener
   */
  onDestroy() {
    publisher.value.off('audioLevelUpdated', this.audioLevelUpdated);
  }
}

export default MicTest;