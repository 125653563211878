
import { defineComponent, ref, watch } from 'vue';
import { 
  agenda,
  triggerAgendaItem,
  updateAgendaItemById,
} from '../../../store/moderator';
import Card from 'primevue/card';

export default defineComponent({
  name: 'Agenda',
  components: {
    Card
  },
  setup() { 
    const updateAgenda = (item) => {
      triggerAgendaItem(item);
    };

    return {
      agenda,
      updateAgenda
    };
  },
})
