import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0b65c35a")
const _hoisted_1 = { class: "details" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "details" }
const _hoisted_4 = ["textContent"]
_popScopeId()

import { defineComponent, markRaw, ref, unref } from 'vue';

import CallActions from '../../../utils/CallActions';

import {
  setCallActive,
  callActive,
  setVideoState,
  setMicrophoneState,
  joinDisabled
} from '../../../store/session';
import { setUserType } from '../../../store/user';
import { PublisherProperties } from '@opentok/client';


export default _defineComponent({
  setup(__props) {


const endCall = () => {
  setVideoState(false);
  setMicrophoneState(false);
  CallActions.disconnect();
  setCallActive(false);
};

const startCall = () => {
  if (joinDisabled.value) return;
  setUserType();
  setCallActive(true);
};


return (_ctx: any,_cache: any) => {
  return (!_unref(callActive))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "call-switch",
        onClick: startCall
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", {
            class: "hover-text",
            textContent: _toDisplayString('JOIN')
          }, null, 8, _hoisted_2)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "call-switch",
        onClick: endCall
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: "hover-text",
            textContent: _toDisplayString('END')
          }, null, 8, _hoisted_4)
        ])
      ]))
}
}

})