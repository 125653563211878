
import { defineComponent, toRefs } from 'vue';

import Attendee from './components/Attendee.vue';
import { clinician } from '../../store/user';
import { pendingAttendees, demoAttendees } from '../../store/attendees';
import PendingAttendee from './components/PendingAttendee.vue';
import { connected } from '../../store/session';


export default defineComponent({
  name: 'AttendeeDrawer',
  components: {
    Attendee,
    PendingAttendee
  },
  props: {
    streams: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const {streams} = toRefs(props);
    const demoObjects = demoAttendees.value;

    const errorHandler = err => {
      alert(err.message);
    };
    // watch(publisherStreamReady, (n, o) => {
    //   if (!clinician.value) return;
    //   if (o == false && n) {
    //     const mod = demoObjects.shift();
    //     mod.streamId = publisher.value.stream.connection.connectionId;
    //     addAttendee(mod);
    //   }
    // })

    return {
      streams,
      clinician,
      pendingAttendees,
      connected,
      errorHandler,
    };
}
});
