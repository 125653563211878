
import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from 'vue';

import { session } from '../../store/session';
import { clinician } from '../../store/user';
import * as whiteboardStore from '../../store/whiteboard';
import { HistoryItem } from '../../utils/Types';

export default defineComponent({
  name: 'Whiteboard',
  setup() {
    const root = ref(null);
    const drawHistoryReceivedFrom = ref(null);
    const drawHistoryReceived = ref(null);
    const eventsString =
      'mousedown mousemove mouseup mouseout touchstart touchmove touchend touchcancel';

    onMounted(() => {
      whiteboardStore.setupWhiteboardPaper(root.value);
      whiteboardStore.updateWhiteboardPaperView();
      initCanvasListener();
    });

    onBeforeUnmount(() => {
      removeListeners();
      whiteboardStore.clearCanvas();
    });

    whiteboardStore.changeColor(whiteboardStore.colors.value[Math.floor(Math.random() * whiteboardStore.colors.value.length)]);

    const initCanvasListener = () => {
      eventsString.split(' ').forEach((e) => {
        root.value.addEventListener(e, whiteboardStore.drawingEvents);
      });
      document.addEventListener('keyup', whiteboardStore.drawingEvents);

      if (session.value) {
        session.value.on({
          sessionConnected: function sessionConnected() {
            whiteboardStore.requestHistory();
          },
          'signal:otWhiteboard_update': (event) => {
            if (
              event.from.connectionId !== session.value.connection.connectionId
            ) {
              whiteboardStore.drawUpdates(JSON.parse(event.data));
            }
          },
          'signal:otWhiteboard_history': (event) => {
            // We will receive these from everyone in the room, only listen to the first
            // person. Also the data is chunked together so we need all of that person's
            if (
              !drawHistoryReceivedFrom.value ||
              drawHistoryReceivedFrom.value === event.from.connectionId
            ) {
              console.log(event.from.connectionId);
              drawHistoryReceivedFrom.value = event.from.connectionId;
              whiteboardStore.drawUpdates(JSON.parse(event.data));
            }
          },
          'signal:otWhiteboard_clear': (event) => {
            if (
              event.from.connectionId !== session.value.connection.connectionId
            ) {
              whiteboardStore.clearCanvas();
            }
          },
          'signal:otWhiteboard_clear_user': (event) => {
              whiteboardStore.clearHistoryByConnectionId(event.data);
          },
          'signal:otWhiteboard_request_history': (event) => {
            if (whiteboardStore.history.value.length > 0) {
              whiteboardStore.batchSignal<HistoryItem>('otWhiteboard_history', whiteboardStore.history.value as HistoryItem[], event.from);
            }
          },
        });
      }
    };

    const removeListeners = () => {
      console.log('listeners destroyed 447');
      document.removeEventListener('keyup', whiteboardStore.drawingEvents);
      eventsString.split(' ').forEach((e) => {
        root.value.removeEventListener(e, whiteboardStore.drawingEvents);
      });
      session.value.off(`signal:otWhiteboard_update signal:otWhiteboard_history signal:otWhiteboard_clear signal:otWhiteboard_request_history signal:otWhiteboard_add_text sessionConnected`);
    };

    return {
      root,
      clinician,
      colors: whiteboardStore.colors,
      color: whiteboardStore.color,
      changeColor: whiteboardStore.changeColor,
      textToggled: whiteboardStore.textToggled,
      erasing: whiteboardStore.erasing,
      clear: whiteboardStore.clearBroadcast,
      toggleEraser: whiteboardStore.toggleEraser,
      toggleText: whiteboardStore.toggleText,
    };
  },
});
