<script lang="ts">

import { defineComponent, ref } from 'vue';
import BrowserTest from '../../../utils/BrowserTest';


export default defineComponent({
  name: 'BrowserCheck',
  setup() {
    const enabled = ref(false);
    const browserTest = new BrowserTest();

    console.log(browserTest.getBrowser());
    return { enabled }
  }
})
</script>

<template>
  <div id="browser-check">
    <div class="browser-logo-container">
      <img alt="chrome logo" width="200" height="200" src="../../../../public/logos/google-chrome.svg"/>
    </div>
    <div class="browser-details">
      <p class="heading">Browser</p>
      <p class="description">
        Chrome is supported by eLuma Classroom
      </p>
      <img alt="green check mark" width="50" height="50" src="../../../assets/icons/green-check.svg"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#browser-check {
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 10px;
  background: #FAFAFC;
  border-radius: 13.3333px;
  .browser-logo-container {
    width: 45%;
    background-color: #F2F2F5;
    border-radius: 10px;
    img {
      margin: 0;
      color: rgba(172, 240, 195, 1);
    }
  }
  .browser-details {
    // flex: 1 1 50%;
    max-width: 19vw;
    height: 100%;
    .heading {
      text-align: left;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 13px;
      letter-spacing: 0.266667px;
      color: #000000;
      margin: 15px 0 0 15px;
    }
    .description {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #8F90A6;
      text-align: left;
      margin: 15px 0 0 15px;
    }
  }
}
</style>