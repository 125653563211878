<script lang="ts">
import { defineComponent, watch, onMounted, ref } from 'vue';

import { whiteboardEnabled, setWhiteboardStatus, setFocusViewElement } from '../../../store/views';

import Button from 'primevue/button';

export default defineComponent({
  name: 'WhiteboardPanel',
  components: {
    Button,
  },
  setup() {
    const toggleWhiteboard = (bool) => {
      setWhiteboardStatus(bool);
      if (bool) {
        setFocusViewElement(document.querySelector('#white_board_canvas'));
        // setFocusViewInfo()
      }
    }
    return {
      setWhiteboardStatus,
      whiteboardEnabled
    }
  }
})
</script>
<template>
  <Button v-if="whiteboardEnabled" label="Stop Whiteboard" @click="setWhiteboardStatus({bool: false, moderator: true})"/>
  <Button v-else label="Start Whiteboard" @click="setWhiteboardStatus({bool: true, moderator: true})"/>
  <div id="white-board-panel"></div>
</template>
<style scoped type="scss">

</style>