<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { 
  agenda,
  triggerAgendaItem,
  updateAgendaItemById,
} from '../../../store/moderator';
import Card from 'primevue/card';

export default defineComponent({
  name: 'Agenda',
  components: {
    Card
  },
  setup() { 
    const updateAgenda = (item) => {
      triggerAgendaItem(item);
    };

    return {
      agenda,
      updateAgenda
    };
  },
})
</script>

<template>
<Card
  v-for="item in agenda"
  :key="item.id"
  class="task-card"
  :class="{active: item.active, done: item.done}"
  @click="updateAgenda({id: item.id, done: !item.done})"
>
  <template #title>
    {{item.title}}
  </template>
  <template #subtitle>
    {{item.subtitle}}
  </template>
</Card>
</template>

<style lang="scss" scoped>

</style>