

import { 
  defineComponent,
  ref,
  watchEffect,
} from 'vue';

import Menu from 'primevue/menu';

import { 
  setMicrophoneState,
  audioInputs,
  setAudioInput,
} from '../../../store/session';

export default defineComponent({
  name: 'MicSwitch',
  components : {
    Menu
  },
  setup() {
    const enabled = ref(true);
    const menu = ref(null);
    const audioInputsMenuData = ref([]);

    const toggleAudio = () => {
      enabled.value = !enabled.value;
      setMicrophoneState(enabled.value);
    };

    const toggleMicList = toggle => {
      menu.value.toggle(toggle);
    };

    const selectAudio = audioInput => {
      setAudioInput(audioInput);
    };

    watchEffect(() => {
      audioInputsMenuData.value = [];
      audioInputs.value?.forEach(audioInput => {
        audioInputsMenuData.value.push({label: audioInput.label, icon: audioInput.selected ? 'pi pi-check' : null, command: () => selectAudio(audioInput)})
      });
    });

    return { 
      enabled,
      menu,
      toggleAudio,
      toggleMicList,
      audioInputsMenuData,
    }
  }
})
