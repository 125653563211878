import Builder from './Builder';

import {
  CallCredentials,
  DisconnectCallOptions,
} from './Types';

import {
  userId,
} from '../store/user';

import {
  setSessionDisconnected,
  setSessionConnected,
} from '../store/session';

export default class CallActions {
  static joinCall(meetingOptions: any) {
    const {meeting_id, bearer_token} = meetingOptions;
    return Builder.get(`/session/user/join/${meeting_id}`, 'join')
      .then((response) => {
        // setSessionConnected();
        console.log(response.data);
        return response.data;
      })
      .catch(e => {throw e});
  }

  static disconnect(user?: number) {
    const options: DisconnectCallOptions = {
      user_id: user || userId.value
    };
    setSessionDisconnected();
    return Builder.post('/session/disconnect', options)
      .then(response => {
        return response.data;
      })
      .catch(e => {throw e});
  }

  // static generateSignature(signatureProperties: {meeting_number: number, role: number}) {
  //   return Builder.post('/sign/generate', signatureProperties)
  //     .then(response => {
  //       setMeetingSignature(response.data);
  //       return response.data;
  //     })
  //     .catch(e => {throw e});
  // }
}