/* eslint-disable */

import axios from 'axios';
import {bearerToken} from '../store/user';

export default class Builder {
  /**
   * The default content type for all requests.
   *
   * @const {String} contentType
   */
  static contentType = 'application/vnd.api+json';

  /**
   * The messages for available status types.
   *
   * @const {Object} messages
   */
  static messages = {
    authentication: {
      duration: 15000,
      group: 'custom-notifications',
      title: 'Not Authorized',
      text: '',
      type: 'error',
    },
    error: {
      duration: 15000,
      group: 'custom-notifications',
      title: 'OOPS!',
      text: '',
      type: 'error',
    },
    timeout: {
      duration: 15000,
      group: 'custom-notifications',
      title: 'Request Timeout',
      text: '',
      type: 'error',
    },
  };

  /**
   * The default response type for all requests.
   *
   * @const {String} responseType
   */
  static responseType = 'json';

  /**
   * Set an error message for all requests that have failed.
   *
   * @param {Object} e
   * @return {Void}
   */
  static error(e, resource?) {
    const statusCode = e.response.status;

    if (statusCode !== 404) {
      resource = 'app';
      let message: any = '';

      if (statusCode === 401) {
        message = this.messages.authentication;
        message.text = (`You are not authorized to access ${resource} data.`);
      } else if (statusCode === 504) {
        message = this.messages.timeout;
        message.text = (`An attempt to request ${resource} data has timed out. Try refreshing this page in order to sort this out.`);
      } else {
        message = this.messages.error;
        message.text = (`An error has occurred while requesting ${resource} data and the development team has been notified.`);
      }

      // window.app.$notify(message);
      return new Error(message);
    }
  }

  /**
   * Create the get request based on the request url.
   *
   * @param {String} url
   * @param {String} resource
   * @return {Object}
   */
  static get(url, resource) {
    return this.getConnection().get(url)
      .then((response) => {
        return response.data;
      })
      .catch((e) => this.error(e, resource));
  }

  /**
   * Get the resource connection for the model.
   *
   * @return {Object}
   */
  static getConnection() {
    // Set the default and expected header configuration for all requests.
    axios.defaults.headers.common = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    };

    if (bearerToken.value) {
      axios.defaults.headers.common.Authorization = `Bearer ${bearerToken.value}`;
    }

    const axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_HOST,
      headers: { 'Content-Type': 'application/vnd.api+json' },
      responseType: 'json',
    });

    axiosInstance.interceptors.response.use(null, error => {
      if (error.response.status === 404) {
        this.error(error);
      } else {
        return Promise.reject(error);
      }
    });

    // Return a configured instance of Axios.
    return axiosInstance;
  }

  /**
   * Get the default content type required for the JSON API specification.
   *
   * @return {Object}
   */
  static getDefaultContentType() {
    return { 'Content-Type': this.contentType };
  }

  /**
   * Update the data associated with the requested end point.
   *
   * @param {String} url
   * @param {Object} object
   * @return {Object}
   */
  static patch(url, object, resource) {
    return this.getConnection().patch(url, object)
      .then((response) => {
        return response.data;
      })
      .catch((e) => this.error(e, resource));
  }

  /**
   * Create the data associated with the requested end point.
   *
   * @param {String} url
   * @param {Object} object
   * @return {Object}
   */
  static post(url, object) {
    return this.getConnection().post(url, object)
      .then((response) => {
        return response.data;
      })
      .catch((e) => this.error(e, 'resource'));
  }
}
