<script lang="ts">

import { defineComponent, onMounted, ref, watch } from 'vue';
import { callActive, screenShareStream } from '../../store/session';
import { clinician } from '../../store/user';
import { whiteboardEnabled, focusViewInfo, setVideoStatus, videoStatus, screenShareActive } from '../../store/views';
import { Player } from '@techassi/vue-youtube-iframe';
import Whiteboard from '../Whiteboard';
import PublisherAdapter from '../../services/PublisherAdapter';
import SubscriberAdapter from '../../services/SubscriberAdapter';

export default defineComponent({
  name: 'FocusView',
  components: {
    Whiteboard,
  },
  setup() {
    const root = ref(null);
    const playerHeight = ref(0);
    const playerWidth = ref(0);
    const videoPlayer = ref(null) as typeof Player;
    const publisherAdapter = ref(null);
    const subscriberAdapter = ref(null);

    const videoStateChange = e => {
      if (e.data === 3 || e.data === 1) {
        setVideoStatus({playing: true, sendSignal: true});
      } else if (e.data === 2) {
        setVideoStatus({playing: false, sendSignal: true});
      }
    }

    const playerParams = {
      controls: clinician.value ? 1 : 0,
      disablekb: 1,
      modestbranding: 1
    };

    onMounted(() => {
      playerHeight.value = document.querySelector('#main_focus_viewer').clientHeight;
      playerWidth.value = document.querySelector('#main_focus_viewer').clientWidth;
      publisherAdapter.value = new PublisherAdapter();
      subscriberAdapter.value = new SubscriberAdapter();

      OT.checkScreenSharingCapability(function(response) {
        if(!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing
          console.log('screenshare not supported')
        } else {
          console.log('screenshare ISSSSSSSSS supported')
        }
      });
    });

    watch(screenShareActive, (newVal, oldVal) => {
      console.log(newVal, oldVal);
      if (clinician.value) {
        if (newVal) {
          publisherAdapter.value.initScreenSharePublisher(root.value);
        } else {
          publisherAdapter.value.unpublish(true);
        }
      }
    });

    watch(screenShareStream, (newVal) => {
      if (newVal) {
        if (!clinician.value) {
          subscriberAdapter.value.subscribe(
            newVal.connection.connectionId,
            root.value,
            {insertMode: 'append', height: '100%', width: '100%', showControls: false},
            (newVal.streamId as string)
          )
        }
      }
    })

    watch(videoStatus, (val, oldVal) => {
      if (focusViewInfo.value.view === 'video' && !clinician.value) {
        //console.log(videoPlayer.value, val, oldVal);
        if (val && !oldVal) {
          videoPlayer.value.playVideo();
        } else {
          videoPlayer.value.pauseVideo();
        }
      }
    });

    return {
      root,
      whiteboardEnabled,
      focusViewInfo,
      videoStateChange,
      playerParams,
      playerHeight,
      playerWidth,
      videoPlayer
    };
  }
})
</script>

<template>
  <div ref="root" id="main_focus_viewer" :class="{whiteboardEnabled}">
    <Whiteboard v-if="whiteboardEnabled"/>
    <youtube-iframe ref="videoPlayer"
      v-if="focusViewInfo?.view === 'video'"
      :video-id="focusViewInfo?.data?.videoId"

      @state-change="videoStateChange"
      :player-parameters="playerParams"
    ></youtube-iframe>
  </div>
</template>

<style lang="scss" scoped>
#main_focus_viewer {
  height: 100%;
  width: 100%;
  &.whiteboardEnabled {
    background-image: url('../../assets/media/maze-test.png');
    background-position-y: center;
    background-repeat: no-repeat;
  }
}
</style>