
import { Stream } from '@opentok/client';
import { defineComponent, onMounted, ref, toRefs, watch } from 'vue';
import SubscriberAdapter from '../../../services/SubscriberAdapter';
import { selectedAudioOutput } from '../../../store/session';
import { clearUserHistory } from '../../../store/whiteboard';
import { clinician } from '../../../store/user';
import UserMenu from './UserMenu.vue';

export default defineComponent({
  name: 'Attendee',
  props: {
    stream: {
      type: Stream,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    UserMenu,
  },
  setup(props) {
    const root = ref(null);
    const { stream, streamId, index } = toRefs(props);
    const idString = ref('OT_subscriber');
    const showButtons = ref(false);
    const showUserMenu = ref(false);
    const focusView = ref(false);
    const subscriberAdapter = new SubscriberAdapter();
    const demoNames = ref(['Nico', 'Lucas']);
    const connectionId = ref((stream.value as Stream).connection.connectionId);

    onMounted(() => {
      subscriberAdapter.subscribe(
        connectionId.value,
        root.value,
        {
          insertMode: 'append',
          height: '100%',
          width: '100%',
          showControls: false,
        },
        streamId.value as string
      );
    });

    watch(selectedAudioOutput, () => {
      subscriberAdapter.attachAudioOutputToVideo();
    });

    const onFullscreen = (e) => {
      if (!focusView.value) {
        document.querySelector('#main_focus_viewer').append(root.value);
        focusView.value = true;
      } else {
        document.querySelector('#subscribers').append(root.value);
        focusView.value = false;
      }
    };

    const setShowUserMenu = (e) => {
      showButtons.value = false;
      showUserMenu.value = true;
    };

    const setHideUserMenu = (e) => {
      console.log("HIDE")
      showUserMenu.value = false;
    };

    return {
      root,
      connectionId,
      idString,
      clinician,
      showButtons,
      showUserMenu,
      setShowUserMenu,
      setHideUserMenu,
      onFullscreen,
      focusView,
      demoNames,
      index,
      stream,
    };
  },
});
