import STATE from './state';
import { ModeratorState } from '../../utils/Types';

import {
    createStore
} from '@harlem/core';

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore<ModeratorState>('moderator', STATE);