<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import SessionAdapter from '../services/SessionAdapter';
// import ZoomAdapter from '../services/ZoomAdapter';
import OT from '@opentok/client';
import AttendeeDrawer from './AttendeeDrawer';
import FocusView from './FocusView';
// import AccCore from 'opentok-accelerator-core';
// import CallTest from '@/utils/CallTest';
import {
  streams,
  callActive,
  connected,
  publisher,
  setSessionConnected,
  publisherStreamReady
} from '../store/session';
import { clinician } from '../store/user';
import { apiKey } from '../store/env';


export default defineComponent({
  name: 'session',
  components: {
    AttendeeDrawer,
    FocusView,
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    meetingId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const session = new SessionAdapter(apiKey.value, props.sessionId, props.token);
    session.initialize();

    const errorHandler = err => {
      alert(err.message);
    };

    return {
      session,
      streams,
      connected,
      callActive,
      clinician,
      errorHandler
    }
  }
});
</script>

<template>
  <div id="session" @error="errorHandler">
    <div class="drawer">
      <AttendeeDrawer v-if="callActive" :streams="streams"/>
    </div>
    <FocusView v-if="callActive"/>
    <!-- <CallDrawer v-if="callActive && clinician"/> -->
  </div>
    <!-- <div v-if="clinician" class="OT_publisher" :class="{video}" id="OT_publisher_custom"></div> -->
</template>

<style lang="scss" scoped>
#session {
  display: flex;
  height: calc(100vh - 96px);
  width: 100%;
  overflow: hidden;
  .drawer {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    .publisher-corner {
      // border-top: #ECECEE 1px solid;
      width: 220px;
      height: 50%;
      display: flex;
      flex-direction: column;
      background: #FAFAFC;
      justify-content: flex-end;
    }
  }
  #OT_publisher_custom {
    position: absolute;
    bottom: 0;
    right: 400px;
    display: none;
    z-index: 1;
    &.video {
      display: block;
    }
  }
}
</style>
