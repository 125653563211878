
import { defineComponent, ref } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';

import {
  agenda,
  media,
  updateAgendaItemById,
  notes,
} from '../../store/moderator';
import { hostMenuOpen, hostMenuSelection } from '../../store/views';
import { clinician } from '../../store/user';
import { session } from '../../store/session';
import WhiteboardPanel from './components/WhiteboardPanel.vue';
import Agenda from './components/Agenda.vue';
import { HostMenuName } from './../../utils/Types';

export default defineComponent({
  name: 'CallDrawer',
  components: {
    TabView,
    TabPanel,
    Card,
    Textarea,
    WhiteboardPanel,
    Agenda,
  },
  setup() {
    const open = ref(true);
    const video = ref(null);
    const videoPublisher = ref(false);

    const updateAgenda = (item) => {
      session.value.signal(
        {
          type: 'agenda',
          data: item,
        },
        (error) => {
          if (error) {
            console.log(error);
          } else {
            console.log('signal sent');
            updateAgendaItemById(item);
          }
        }
      );
    };

    return {
      open,
      agenda,
      updateAgenda,
      video,
      media,
      clinician,
      notes,
      hostMenuOpen,
      hostMenuSelection,
      HostMenuName,
    };
  },
});
