import { STATE } from './state';
import { UserState } from '../../utils/Types';

import {
    createStore
} from '@harlem/core';

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore<UserState>('user', STATE);