
import { defineComponent, watch, onMounted, ref } from 'vue';

import { whiteboardEnabled, setWhiteboardStatus, setFocusViewElement } from '../../../store/views';

import Button from 'primevue/button';

export default defineComponent({
  name: 'WhiteboardPanel',
  components: {
    Button,
  },
  setup() {
    const toggleWhiteboard = (bool) => {
      setWhiteboardStatus(bool);
      if (bool) {
        setFocusViewElement(document.querySelector('#white_board_canvas'));
        // setFocusViewInfo()
      }
    }
    return {
      setWhiteboardStatus,
      whiteboardEnabled
    }
  }
})
