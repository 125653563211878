<script lang="ts">
import { defineComponent, ref } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';

import {
  agenda,
  media,
  updateAgendaItemById,
  notes,
} from '../../store/moderator';
import { hostMenuOpen, hostMenuSelection } from '../../store/views';
import { clinician } from '../../store/user';
import { session } from '../../store/session';
import WhiteboardPanel from './components/WhiteboardPanel.vue';
import Agenda from './components/Agenda.vue';
import { HostMenuName } from './../../utils/Types';

export default defineComponent({
  name: 'CallDrawer',
  components: {
    TabView,
    TabPanel,
    Card,
    Textarea,
    WhiteboardPanel,
    Agenda,
  },
  setup() {
    const open = ref(true);
    const video = ref(null);
    const videoPublisher = ref(false);

    const updateAgenda = (item) => {
      session.value.signal(
        {
          type: 'agenda',
          data: item,
        },
        (error) => {
          if (error) {
            console.log(error);
          } else {
            console.log('signal sent');
            updateAgendaItemById(item);
          }
        }
      );
    };

    return {
      open,
      agenda,
      updateAgenda,
      video,
      media,
      clinician,
      notes,
      hostMenuOpen,
      hostMenuSelection,
      HostMenuName,
    };
  },
});
</script>

<template>
  <div id="call_drawer" :class="{ open: hostMenuOpen }">
    <Agenda v-if="hostMenuSelection === 'agenda'" />
    <template v-if="hostMenuSelection === HostMenuName.NOTES">
      <Card v-for="note in notes" :key="note.id" class="note-card">
        <template #title>
          {{ note.title }}
        </template>
        <template #content>
          <Textarea v-model="note.content" rows="8" cols="38" />
        </template>
      </Card>
    </template>
    <template v-if="hostMenuSelection === HostMenuName.WHITEBOARD">
      <WhiteboardPanel />
    </template>
  </div>
</template>

<style lang="scss" scoped>
#call_drawer {
  min-width: 350px;
  height: calc(100vh - 96px);
  background-color: #ffffff;
  margin: 0 10px;
  display: none;
  border-radius: 15px;
  &.open {
    display: initial;
  }
}
::v-deep(.p-inputtextarea) {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  border: 1px solid #f2f2f5;
  letter-spacing: 0.2px;
  color: #c7c9d9;
}
::v-deep(.note-card.p-card) {
  width: 350px;
  border: 1px solid #f2f2f5;

  .p-card-body {
    padding: 8px 10px;
    text-align: left;
  }
}
::v-deep(.media-card.p-card) {
  width: 164px;
  height: 115px;
  border: 1px solid #ebebf0;
  border-radius: 8px;
  margin: 8px;
  position: relative;
  &:hover {
    box-shadow: 2px 2px 18px #e5e5e5;
  }
  .p-card-header {
    cursor: pointer;
    position: absolute;
    video.player {
      display: none;
    }
    div.play-button {
      position: absolute;
      height: 56px;
      width: 56px;
      top: 14px;
      left: 55px;
      background-color: rgba(0, 0, 0, 0.18);
      border-radius: 28px;
      img {
        height: 16px;
        width: 14px;
        position: absolute;
        top: 18px;
        left: 23px;
      }
    }
    img.video-thumbnail {
      border-radius: 8px;
      width: 164px;
      height: 115px;
    }
  }
  .p-card-body {
    text-align: left;
    position: relative;
    margin-top: 80px;
    background: white;
    width: 154px;
    height: 32px;
    padding: 3px 5px 0;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    .p-card-title {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: #000;
    }
    .p-card-subtitle {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      color: #8f90a6;
    }
  }
}
::v-deep(.task-card.p-card) {
  height: 67px;
  border: 1px solid #ebebf0;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px 15px;
  cursor: pointer;
  .p-card-body {
    padding: 15px 20px;
    text-align: left;
    height: 100%;
    .p-card-title {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      color: #28293d;
    }
    .p-card-subtitle {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #555770;
    }
  }
  &.active {
    background-color: #fafafc;
    border: 1px solid #5b8def;
  }
  &.done {
    background-color: #f2f2f5;
    .p-card-title {
      color: #8f90a6;
    }
    .p-card-subtitle {
      color: #8f90a6;
    }
  }
}

</style>