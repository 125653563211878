
import { mutation } from './store';
import * as paper from 'paper';
import { HistoryItem, WhiteboardColors } from '../../utils/Types';
import { markRaw } from 'vue';

export const updateHistory = mutation('updateHistory', (state, historyItem: HistoryItem) => {
  state.history.push(historyItem);
});

export const resetHistory = mutation('resetHistory', (state) => {
  state.history = [];
});

/**
 * clear an individual whiteboard history triggered from the clinician
 */
export const clearHistoryByConnectionId = mutation('clearUserHistory', (state, connectionId) => {
  clearStateHistory(state, connectionId);
  const segmentsToRemove = state.whiteboardPaper.project.getItems({ data: { connectionId } });
  segmentsToRemove.forEach(segment => segment.remove());
});

//TODO: eventually we will use this to update new users that came in late to whiteboard
const clearStateHistory = (state, connectionId) => {
  const mapped = state.history.reduce((results, item) => {
    if (item.value.id.toString().trim() !== connectionId.toString().trim()) {
      results.push(item);
    }
    return results;
  }, []);

  state.history = mapped;
}

export const setupWhiteboardPaper = mutation('setWhiteboardPaper', (state, canvas: HTMLCanvasElement) => {
  state.whiteboardPaper = markRaw(paper);
  state.canvas = canvas;
  state.whiteboardPaper.setup(canvas);
  state.whiteboardPaper.view.viewSize = new state.whiteboardPaper.Size(
    canvas.width,
    canvas.height
  );
});

export const updateWhiteboardPaperView = mutation('updateWhiteboardPaperView', (state) => {
  // state.whiteboardPaper.view.update();
});

export const clearWhiteboardProject = mutation('clearWhiteboardProject', (state) => {
  state.whiteboardPaper.project.clear();
});

export const toggleEraser = mutation('toggleEraser', (state, forceState?: boolean) => {
  if (state.textToggled) toggleText(false);
  state.erasing = forceState !== undefined ? forceState : !state.erasing;
});

export const toggleText = mutation('toggleText', (state, forceState?: boolean) => {
  if (state.erasing) toggleEraser(false);
  state.textToggled = forceState !== undefined ? forceState : !state.textToggled;
});

export const changeColor = mutation('changeColor', (state, selectedColor: WhiteboardColors) => {
  state.color = selectedColor;
  toggleEraser(false);
  toggleText(false);
});