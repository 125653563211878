import { toRaw } from 'vue';
import { mutation } from './store';
import { session } from '../session';
import { HostMenuName } from '../../utils/Types';

export const setFocusViewElement = mutation('setFocusViewElement', (state, {view, data}) => {
  if (view === 'whiteboard') {
    setWhiteboardStatus({bool: true});
    state.focusViewElement = document.querySelector('#white_board_canvas');
  } else if (view === 'video') {
    state.focusViewElement = document.querySelector('#main_focus_viewer');
    setWhiteboardStatus({bool: false});
  } else {
    state.focusViewElement = document.querySelector('#main_focus_viewer');
    setWhiteboardStatus({bool: false});
  }
  setFocusViewInfo({view, data})
});

export const setVideoStatus = mutation('setVideoStatus', (state, {playing, sendSignal}) => {
  if (sendSignal) {
    session.value.signal(
      {
        type: 'video',
        data: playing
      },
      error => {
        if (error) console.log(error)
        else {
          console.log('video playing '+ playing);
        }
      }
    )
  }
  state.videoStatus = playing;
})

export const setFocusViewInfo = mutation('setFocusViewInfo', (state, {view, data}) => {
  console.log({view, data});
  state.focusViewInfo = {view, data};
});

export const setWhiteboardStatus = mutation('setWhiteboardStatus', (state, {bool, moderator = false}) => {
  if (moderator) {
    //@ts-ignore
    toRaw(session.value).signal({type: 'whiteboard', data: JSON.stringify(bool)});
  }
  state.whiteboardEnabled = bool;
});

export const setHostMenuOpen = mutation('setHostMenuOpen', (state, payload: boolean) => {
  console.log(payload);
  state.hostMenuOpen = payload;
});

export const setHostMenuSelection = mutation('setHostMenuSelection', (state, payload: HostMenuName) => {
  state.hostMenuSelection = payload;
});

export const setScreenShareActive = mutation('setScreenShareActive', (state, active: boolean) => state.screenShareActive = active);