import STATE from './state';
import { ChatState } from '../../utils/Types';

import {
    createStore
} from '@harlem/core';

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore<ChatState>('chat', STATE);