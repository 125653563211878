<script lang="ts">
import { defineComponent, ref } from 'vue';

import ElapsedTime from './components/ElapsedTime.vue';
import VideoSwitch from './components/VideoSwitch.vue';
import MicSwitch from './components/MicSwitch.vue';
import SpeakerSwitch from './components/SpeakerSwitch.vue';
import ToggleCall from './components/ToggleCall.vue';
import ScreenShareSwitch from './components/ScreenShareSwitch.vue';
import TabMenu from 'primevue/tabmenu';

import { connected, callActive } from '../../store/session';
import {
  setHostMenuOpen,
  setHostMenuSelection,
  hostMenuSelection,
  hostMenuOpen,
} from '../../store/views';

import { clinician } from '../../store/user';
import { HostMenuName } from '../../utils/Types';

export default defineComponent({
  name: 'CallHeader',
  components: {
    ElapsedTime,
    VideoSwitch,
    MicSwitch,
    SpeakerSwitch,
    ScreenShareSwitch,
    ToggleCall,
    TabMenu,
  },
  setup() {
    const menuItems = ref([
      { label: 'AGENDA', command: () => togglePanel(HostMenuName.AGENDA) },
      { label: 'NOTES', command: () => togglePanel(HostMenuName.NOTES) },
      {
        label: 'WHITEBOARD',
        command: () => togglePanel(HostMenuName.WHITEBOARD),
      },
    ]);

    const togglePanel = (menuName: HostMenuName) => {
      if (hostMenuOpen.value && menuName === hostMenuSelection.value) {
        console.log('close');
        setHostMenuOpen(false);
        return;
      }
      setHostMenuOpen(true);
      setHostMenuSelection(menuName);
    };

    return {
      connected,
      callActive,
      clinician,
      menuItems,
      hostMenuSelection,
      hostMenuOpen,
    };
  },
});
</script>

<template>
  <div class="header">
    <img
      alt="eLuma logo"
      width="190"
      height="40"
      src="../../../public/logos/eluma-svg.svg"
    />
    <div class="controls">
      <VideoSwitch />
      <MicSwitch />
      <SpeakerSwitch />
      <ScreenShareSwitch v-if="clinician && connected" />
      <ElapsedTime v-if="connected" />
      <ToggleCall v-if="connected" />
      <div
        v-if="callActive && clinician"
        class="host-panel-buttons-group"
        :class="{ active: hostMenuOpen }"
      >
        <div
          v-for="item in menuItems"
          :key="item.label"
          class="host-panel-button"
          @click="item.command"
        >
          <span
            class="hover-text"
            :class="{
              active:
                hostMenuSelection === item.label.toLowerCase() && hostMenuOpen,
            }"
            v-text="item.label"
          />
        </div>
      </div>
      <!-- <TabMenu v-if="callActive" :model="menuItems"/> -->
      <!-- <CallDrawer v-if="callActive && clinician"/> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fafafc;
  img {
    margin: 0;
  }
  .controls {
    width: 70%;
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    .host-panel-buttons-group {
      display: flex;
      width: 350px;
      height: 65px;
      align-items: center;
      margin: 0 10px;
      border-radius: 15px 15px 0 0;
      &.active {
        background-color: #fff;
      }
      .host-panel-button {
        display: flex;
        flex-direction: column;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 10px;
        color: #8f90a6;
        cursor: pointer;
        text-align: center;
        width: 100%;
        .hover-text {
          &.active {
            color: #0063f7;
          }
        }
      }
    }
  }
}
</style>