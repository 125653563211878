<script lang="ts">

import {
  defineComponent,
  ref,
  watch,
  onUnmounted,
} from 'vue';
import {
  audioInputs,
  selectedAudioInput,
  setAudioInput,
  publisher,
} from '../../../store/session';

import MicTest from '../../../utils/MicTest';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'MicPicker',
  components: {
    Dropdown
  },
  setup() {
    const audio = ref(null);
    const canvas = ref(null);
    let micLevel;

    watch(selectedAudioInput, (selectedAudioInput, old) => {
      audio.value = selectedAudioInput;
    });

    watch(publisher, (publisher, old) => {
      if (!old && publisher?.id) {
        micLevel = new MicTest({canvas: canvas.value});
        micLevel.init();
      }
    })

    const selectAudio = ({value}) => {
      setAudioInput(value);
    };

    onUnmounted(() => {
      micLevel.onDestroy();
    });

    return {
      canvas,
      audioInputs,
      audio,
      selectAudio,
    }
  }
})
</script>

<template>
  <div id="mic-picker">
    <div class="mic-guage-container">
      <img alt="mic icon" width="48" height="48" src="../../../assets/icons/green-mic.svg">
      <canvas ref="canvas" class="mic-guage"></canvas>
    </div>
    <div class="audio-selector">
      <p class="heading">Microphone</p>
      <p class="description">
        Say something in the microphone to make sure it’s working.
      </p>
      <Dropdown
        
        v-model="audio"
        :options="audioInputs"
        optionLabel="label"
        :empty-message="'No microphones found'"
        @change="selectAudio"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#mic-picker {
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 10px;
  background: #FAFAFC;
  justify-content: space-around;
  border-radius: 13.3333px;
  .mic-guage-container {
    background-color: #F2F2F5;
    border-radius: 10px;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    img {
      align-self: center;
      margin: 0;
    }
    .mic-guage {
      width: 100%;
      padding: 0 20px;
      height: 37.33px;
    }
  }
  .audio-selector {
    max-width: 19vw;
    height: 100%;
    .heading {
      text-align: left;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 13px;
      letter-spacing: 0.266667px;
      color: #000000;
      margin: 15px 0 0 15px;
    }
    .description {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #8F90A6;
      text-align: left;
      margin: 15px 0 0 15px;
    }
  }
  ::v-deep(.p-dropdown) {
    width: 16vw;
    margin-top: 20px;
    height: 50px;
    .p-dropdown-label {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.266667px;
      color: #28293D;
      align-self: center;
    }
  }
}
</style>