<script lang="ts">

import {
  defineComponent,
  ref,
  watch,
  onUnmounted,
  onMounted,
  provide
} from 'vue';
import {
  audioOutputs,
  selectedAudioOutput,
  setAudioOutput,
  publisher,
} from '../../../store/session';

// import SpeakerTest from '../../../utils/SpeakerTest';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'SpeakerPicker',
  components: {
    Dropdown
  },
  setup() {
    const audio = ref(null);
    const testSpeakerEl = ref(null);
    const noBrowserSupport = ref(false);

    watch(selectedAudioOutput, selectedAudioOutput => {
        if (noBrowserSupport.value) return;
        audio.value = selectedAudioOutput;
        testSpeakerEl.value.setSinkId(audio.value.deviceId);
    });

    watch(audioOutputs, (audioOutputs, old) => {
      if (!old && audioOutputs.length) {
        console.log(audioOutputs);
      }
    });

    const selectAudio = ({value}) => {
      console.log(value);
      setAudioOutput(value);
    };

    const testSpeaker = () => {
      testSpeakerEl.value.play();
    }

    onMounted(() => {
      const url = require('../../../../public/test-audio.mp3');
      testSpeakerEl.value = new Audio(url);
      if ((typeof (testSpeakerEl.value as any).sinkId === 'undefined')) {
        noBrowserSupport.value = true;
      } else {
        noBrowserSupport.value = false;
      }
    });

    onUnmounted(() => {
      testSpeakerEl.value = '';
    });

    provide('noBrowserSupport', noBrowserSupport);

    return {
      audioOutputs,
      audio,
      selectAudio,
      testSpeaker,
      noBrowserSupport,
    }
  }
})
</script>

<template>
  <div id="speaker-picker">
    <div class="speaker-guage-container">
      <img class="speaker-icon" alt="speaker icon" width="48" height="48" src="../../../assets/icons/sound-gauge.svg"/>
      <button @click="testSpeaker" class="speaker-test">Test Speaker<img alt="test speaker icon" width="10" height="12" src="../../../assets/icons/test-speaker.svg"/></button>
    </div>
    <div class="speaker-selector">
      <p class="heading">Speakers</p>
      <p v-if="!noBrowserSupport" class="description">
        Select and test the device you would like to hear audio from.
      </p>
      <p v-else class="description">
        Your browser does not support speaker selection, change your speaker through your computer settings.
      </p>
      <Dropdown
        v-if="!noBrowserSupport"
        v-model="audio"
        :options="audioOutputs"
        optionLabel="label"
        :change="selectAudio"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#speaker-picker {
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 10px;
  background: #FAFAFC;
  justify-content: space-around;
  border-radius: 13.3333px;
  .speaker-guage-container {
    background-color: #F2F2F5;
    border-radius: 10px;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .speaker-icon {
      align-self: center;
      margin: 0;
    }
    .speaker-test {
      width: 137px;
      height: 40px;
      border-radius: 6px;
      background-color: #fff;
      display: flex;
      align-items: center;
      margin: 0 auto;
      cursor: pointer;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #555770;
      letter-spacing: 0.2px;
      text-align: center;
      justify-content: space-around;
    }
  }
  .speaker-selector {
    max-width: 19vw;
    height: 100%;
    .heading {
      text-align: left;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 13px;
      letter-spacing: 0.266667px;
      color: #000000;
      margin: 15px 0 0 15px;
    }
    .description {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #8F90A6;
      text-align: left;
      margin: 15px 0 0 15px;
    }
  }
  ::v-deep(.p-dropdown) {
    width: 16vw;
    margin-top: 20px;
    height: 50px;
    .p-dropdown-label {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.266667px;
      color: #28293D;
      align-self: center;
    }
  }
}
</style>