import { ChatState, MessageType } from "../../utils/Types";

const STATE: ChatState = {
  open: false,
  chatGroupSelected: 'all',
  chatGroups: {'all': {
    name: 'All',
    messages: [{
      messageType: MessageType.Public,
      from: 'SERVER',
      to: 'all',
      messages: [
        {message: 'Welcome! Messages here can be read by everyone on the call.', timestamp: new Date().toLocaleTimeString('en-US')},
      ]
    }
  ],
    active: true,
  }},
};

export default STATE;