import {
  sessionSubscribe, 
  selectedAudioOutput,
} from '../store/session';
import OT, { Subscriber } from '@opentok/client';
import { addAttendee } from '@/store/attendees';

export default class SubscriberAdapter {
  private subscriber: Subscriber;
  private videoEl: HTMLVideoElement | HTMLObjectElement;

  // constructor() {
  //   super();
  //   // this.initListeners();
  // }

  public subscribe(
    connectionId: string,
    element: string | HTMLElement,
    properties: OT.SubscriberProperties,
    streamId: string
  ) {
    this.subscriber = sessionSubscribe({
      connectionId,
      element,
      properties,
      streamId,
    });

    this.subscriber.on('videoElementCreated', ({element}) => {
      this.videoEl = element;
      this.attachAudioOutputToVideo()
    });

    // addAttendee({})
    // this.publisher = OT.initPublisher(replacementElementId, properties, callback);
  }

  public attachAudioOutputToVideo(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (typeof (this.videoEl as any).sinkId !== 'undefined') {
        return reject('device does not support setting the audio output');
      } else {
        return (this.videoEl as any).setSinkId(selectedAudioOutput.value.deviceId)
          .then(() => {
            return resolve('successfully set the audio output device');
          })
          .catch(err => {
            return reject('Failed to set the audio output device');
            // console.error('Failed to set the audio output device ', err);
          });
      }
    });
    // } else {
    //   console.warn('device does not support setting the audio output');
    //   return Promise.reject('device does not support setting the audio output');
    // }
  }

  // private initListeners() {
  //   this.publisher.on({
  //     streamCreated: event => {
  //       console.log('publisher stream created');
  //     },
  //     streamDestroyed: event => {
  //       console.log('publisher stream destroyed');
  //     }
  //   });
  // }

  // public publish() {
  //   session.value
  //     .publish(this.publisher);
  // }

  // public unpublish() {
  //   session.value.unpublish(this.publisher);
  // }

  // private initInputDevices() {
  //   let audioInputs;
  //   let videoInputs;

  //   OT.getDevices((err, devices) => {
  //     audioInputs = devices.filter((device) => device.kind === 'audioInput');
  //     videoInputs = devices.filter((device) => device.kind === 'videoInput');
      
  //     console.log(this.publisher.getAudioSource())
  //     audioInputs.forEach((device) => {
  //       if (device.label === this.publisher.getAudioSource().label) {
  //         device.selected = true;
  //       } else {
  //         device.selected = false;
  //       }
  //     });
      
  //     videoInputs.forEach((device) => {
  //       if (device.deviceId === this.publisher.getVideoSource().deviceId) {
  //         device.selected = true;
  //       } else {
  //         device.selected = false;
  //       }
  //     });

  //     setAvailableAudioInputs(audioInputs);
  //     setAvailableVideoInputs(videoInputs);
  //   });
  // }
}