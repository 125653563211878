import { toRaw } from 'vue';

import { ChatGroup, ChatMessage, ChatMessageGroup, MessageType } from '../../utils/Types';

import { mutation } from './store';
import {
  streams,
  session,
} from '../session';
import { displayName } from '../user';
import { attendees } from '../attendees';

export const toggleChatWindow = mutation('toggleChatWindow', (state, payload: boolean) => {
  state.open = payload;
});

export const setChatGroupSelected = mutation('setChatGroupSelected', (state, payload: string) => {
  state.chatGroupSelected = payload;
});

export const addNewChatGroup = mutation('addNewChatGroup', (state, {id, name, color}: {id: string} & Pick<ChatGroup, "name" | "color">) => {
  state.chatGroups[id] = {
    name,
    color,
    active: true,
    messages: []
  };
});

export const addNewChatMessage = mutation('addNewChatMessage', (state, {from, to, message, messageType}: Pick<ChatMessageGroup, 'messageType' | 'from' | 'to'> & Pick<ChatMessage, 'message'>) => {
  let messages = null;
  const myId = session.value.connection?.connectionId;
  if (messageType === MessageType.Public) {
    messages = state.chatGroups['all'].messages;
  } else {
    const chatGroupId = from === myId ? to : from;
    messages = state.chatGroups[chatGroupId].messages;
  }
  if (messages.length) {
    const lastIndex = messages.length - 1;
    const lastSenderSameAsCurrent = messages[lastIndex].from === from;
    console.log(messages, lastSenderSameAsCurrent);
    if (lastSenderSameAsCurrent) {
      messages[lastIndex].messages.push({message, timestamp: new Date().toLocaleTimeString('en-US')});
      return;
    }
  }
  let name = from === myId ? displayName.value : attendees.value[from].name;
  messages.push({name, messageType, from, to, messages: [{message, timestamp: new Date().toLocaleTimeString('en-US')}] });
  console.log(messages);
});

export const sendChatMessage = mutation('sendChatMessage', (state, {to, message}: Pick<ChatMessageGroup, 'to'> & Pick<ChatMessage, 'message'>) => {
  let payload = {};
  if (to) {
    const recipientConnection = streams.value[attendees.value[to].streamId].connection;
    payload = {to: recipientConnection, data: message, type: 'private_message'};
  } else {
    payload = {data: message, type: 'public_message'};
  }
  console.log(payload);
  toRaw(session.value).signal(
    payload,
    error => {
      if (error) {
        console.log(`message not sent (${error.name}): ${error.message}`);
      } else {
        console.log(`message sent to: ${to}`);
        if (to) {
          addNewChatMessage({from: session.value.connection?.connectionId, to, message, messageType: MessageType.Private });
        }
      }
    }
  );
});