<script lang="ts">
import { defineComponent, onMounted, onUpdated, ref, watch } from 'vue';
import { setPublisher, callActive, connected, publisherStreamReady, audioActive, videoActive } from '../store/session';
import PublisherAdapter from '../services/PublisherAdapter';
import { PublisherProperties } from '@opentok/client';
import { clinician, displayName } from '../store/user';

export default defineComponent({
  name: 'Publisher',
  setup() {
    const root = ref(null);
    const focusView = ref(false);
    const previewPublisherProperties = {
      insertMode: 'replace', 
      publishAudio: true,
      publishVideo: true,
      showControls: false,
      fitMode: 'contain',
      name: displayName.value
    };
    const publisherProperties = ref({
      insertMode: 'append', 
      publishAudio: audioActive.value,
      publishVideo: videoActive.value,
      height: '100%',
      width: '100%',
      showControls: false,
      name: displayName.value
      // fitMode: 'contain',
    });
    const publisherAdapter = new PublisherAdapter();

    onMounted(() => {
      if (!callActive.value) {
        publisherAdapter.initPublisher(root.value, previewPublisherProperties as PublisherProperties);
      } else {
        publisherAdapter.initPublisher(root.value, publisherProperties.value as PublisherProperties);
      }
    });
    // onUpdated(() => {
    //   console.log(callActive.value);
    // });

    // watch(publisherStreamReady, (n, o) => {
    //   console.log(n);
    //   if (n && clinician.value) {
    //     // document.querySelector('#main_focus_viewer').append(root.value);
    //     // focusView.value = true;
    //   }
    // });
    
    return { root, callActive, connected, focusView };
  }
});
</script>

<template>
<!-- <teleport v-if="!callActive && connected" to="#camera-picker .publisher-placeholder"> -->
<div v-if="!callActive && connected" id="OT_publisher_preview" ref="root"></div>
<!-- </teleport> -->
<div v-else-if="connected" id="OT_publisher" :class="{focused: focusView}" ref="root"></div>
</template>

<style lang="scss" scoped>
#OT_publisher {
  margin: 24px 12px !important;
  width: 186px !important;
  height: 120px !important;
  overflow: hidden !important;
  border-radius: 6px;
  &.focused {
    height: 100%!important;
    width: 100%!important;
  }
}
#OT_publisher_preview {
  width: 242px !important;
  height: 150px !important;
  border-radius: 10px !important;
}
</style>