<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from 'vue';

import { session } from '../../store/session';
import { clinician } from '../../store/user';
import * as whiteboardStore from '../../store/whiteboard';
import { HistoryItem } from '../../utils/Types';

export default defineComponent({
  name: 'Whiteboard',
  setup() {
    const root = ref(null);
    const drawHistoryReceivedFrom = ref(null);
    const drawHistoryReceived = ref(null);
    const eventsString =
      'mousedown mousemove mouseup mouseout touchstart touchmove touchend touchcancel';

    onMounted(() => {
      whiteboardStore.setupWhiteboardPaper(root.value);
      whiteboardStore.updateWhiteboardPaperView();
      initCanvasListener();
    });

    onBeforeUnmount(() => {
      removeListeners();
      whiteboardStore.clearCanvas();
    });

    whiteboardStore.changeColor(whiteboardStore.colors.value[Math.floor(Math.random() * whiteboardStore.colors.value.length)]);

    const initCanvasListener = () => {
      eventsString.split(' ').forEach((e) => {
        root.value.addEventListener(e, whiteboardStore.drawingEvents);
      });
      document.addEventListener('keyup', whiteboardStore.drawingEvents);

      if (session.value) {
        session.value.on({
          sessionConnected: function sessionConnected() {
            whiteboardStore.requestHistory();
          },
          'signal:otWhiteboard_update': (event) => {
            if (
              event.from.connectionId !== session.value.connection.connectionId
            ) {
              whiteboardStore.drawUpdates(JSON.parse(event.data));
            }
          },
          'signal:otWhiteboard_history': (event) => {
            // We will receive these from everyone in the room, only listen to the first
            // person. Also the data is chunked together so we need all of that person's
            if (
              !drawHistoryReceivedFrom.value ||
              drawHistoryReceivedFrom.value === event.from.connectionId
            ) {
              console.log(event.from.connectionId);
              drawHistoryReceivedFrom.value = event.from.connectionId;
              whiteboardStore.drawUpdates(JSON.parse(event.data));
            }
          },
          'signal:otWhiteboard_clear': (event) => {
            if (
              event.from.connectionId !== session.value.connection.connectionId
            ) {
              whiteboardStore.clearCanvas();
            }
          },
          'signal:otWhiteboard_clear_user': (event) => {
              whiteboardStore.clearHistoryByConnectionId(event.data);
          },
          'signal:otWhiteboard_request_history': (event) => {
            if (whiteboardStore.history.value.length > 0) {
              whiteboardStore.batchSignal<HistoryItem>('otWhiteboard_history', whiteboardStore.history.value as HistoryItem[], event.from);
            }
          },
        });
      }
    };

    const removeListeners = () => {
      console.log('listeners destroyed 447');
      document.removeEventListener('keyup', whiteboardStore.drawingEvents);
      eventsString.split(' ').forEach((e) => {
        root.value.removeEventListener(e, whiteboardStore.drawingEvents);
      });
      session.value.off(`signal:otWhiteboard_update signal:otWhiteboard_history signal:otWhiteboard_clear signal:otWhiteboard_request_history signal:otWhiteboard_add_text sessionConnected`);
    };

    return {
      root,
      clinician,
      colors: whiteboardStore.colors,
      color: whiteboardStore.color,
      changeColor: whiteboardStore.changeColor,
      textToggled: whiteboardStore.textToggled,
      erasing: whiteboardStore.erasing,
      clear: whiteboardStore.clearBroadcast,
      toggleEraser: whiteboardStore.toggleEraser,
      toggleText: whiteboardStore.toggleText,
    };
  },
});
</script>

<template>
<div class="whiteboard-container">
  <div id="whiteboard-toolbar" class="OT_panel">
    <input
      v-for="(colorEl, i) in colors"
      :key="i"
      type="button"
      :class="{
        OT_color: true,
        OT_selected: colorEl === color,
      }"
      :style="{backgroundColor: colorEl}"
      @click="changeColor(colorEl)"
    />
    <template v-if="clinician">
      <input
      type="button"
      @click="toggleText()"
      :class="{ OT_text: true, OT_selected: textToggled }"
      value="Text"
      />
      <input type="button" @click="clear" class="OT_clear" value="Clear" />
    </template>
    <input
      type="button"
      @click="toggleEraser()"
      :class="{ OT_erase: true, OT_selected: erasing }"
      value="Eraser"
    />
  </div>
  <canvas id="white_board_canvas" ref="root" hidpi="off"></canvas>
</div>
</template>

<style scoped lang="scss">
.whiteboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  #whiteboard-toolbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 52px;
  }
}
canvas {
  display: flex;
  width: 100%;
  height: 100%;
  border: 3px solid black;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
}

div.OT_panel {
  /* background-color: #424242; */
  padding: 5px;
  border-radius: 5px 0 0 5px;
  top: 10px;
  right: 0;
}

div.OT_panel input,
div.OT_panel .OT_capture {
  display: block;
  -webkit-appearance: none;
  border: none;
  outline: none;
  margin-top: 2px;
  font-size: 9px;
  font-family: sans-serif;
  cursor: pointer;
}

div.OT_panel input.OT_clear,
div.OT_panel .OT_capture {
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #7d7d7d;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
  padding: 0;
}

div.OT_panel input.OT_erase {
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #7d7d7d;
  border-radius: 50%;
  text-align: center;
  padding: 0;
}

div.OT_panel input.OT_color {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

div.OT_panel input.OT_selected {
  border: 4px solid #eaeaea;
}
</style>