

import {
  defineComponent,
  ref,
} from 'vue';

import { setScreenShareActive } from '../../../store/views';

export default defineComponent({
  name: 'ScreenShareSwitch',
  setup() {
    const enabled = ref(false);

    const startScreenShare = () => {
      enabled.value = !enabled.value;
      setScreenShareActive(enabled.value);
    };

    return {
      enabled,
      startScreenShare,
    }
  }
})
