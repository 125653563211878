<script lang="ts">

import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import {
  videoInputs,
  selectedVideoInput,
  setVideoInput
} from '../../../store/session';

import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'CameraPicker',
  components: {
    Dropdown
  },
  setup() {
    const video = ref(null);

    watch(selectedVideoInput, (selectedVideoInput, old) => {
      if (!old) {
        video.value = selectedVideoInput;
      }
    });

    const selectVideo = ({value}) => {
      setVideoInput(value);
    };

    return {
      selectVideo,
      videoInputs,
      video,
    }
  }
})
</script>

<template>
  <div id="camera-picker">
    <div class="publisher-placeholder">
      <publisher></publisher>
    </div>
    <div class="video-selector">
      <p class="heading">Video</p>
      <p class="description">
        Wave your hand and make sure you can see yourself.
      </p>
      <Dropdown
        v-if="videoInputs && videoInputs.length && video"
        v-model="video"
        :options="videoInputs"
        optionLabel="label"
        @change="selectVideo"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#camera-picker {
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 10px;
  background: #FAFAFC;
  border-radius: 13.3333px;
  .publisher-placeholder {
    width: 242px;
    height: 150px;
  }
  .video-selector {
    max-width: 19vw;
    height: 100%;
    .heading {
      text-align: left;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 13px;
      letter-spacing: 0.266667px;
      color: #000000;
      margin: 15px 0 0 15px;
    }
    .description {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #8F90A6;
      text-align: left;
      margin: 15px 0 0 15px;
    }
  }
  ::v-deep(.p-dropdown) {
    width: 16vw;
    margin-top: 20px;
    height: 50px;
    .p-dropdown-label {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.266667px;
      color: #28293D;
      align-self: center;
    }
  }
}
</style>