<script lang="ts">

import { 
  defineComponent, 
  ref,
  watchEffect,
} from 'vue';

import Menu from 'primevue/menu';

import { 
  setVideoState, 
  videoInputs, 
  setVideoInput, 
} from '../../../store/session';

export default defineComponent({
  name: 'VideoSwitch',
  components: {
    Menu
  },
  setup() {
    const enabled = ref(true);
    const menu = ref(null);
    const videoInputsMenuData = ref([]);

    const toggleVideo = () => {
      enabled.value = !enabled.value;
      setVideoState(enabled.value);
    };

    const toggleCameraList = toggle => {
      menu.value.toggle(toggle);
    };

    const selectVideo = videoInput => {
      setVideoInput(videoInput);
    };

    watchEffect(() => {
      videoInputsMenuData.value = [];
      videoInputs.value?.forEach(videoInput => {
        videoInputsMenuData.value.push({label: videoInput.label, icon: videoInput.selected ? 'pi pi-check' : null, commaned: () => selectVideo(videoInput)})
      });
    });

    return { 
      enabled, 
      menu,
      toggleVideo,
      toggleCameraList,
      videoInputsMenuData,
    }
  }
})
</script>

<template>
  <div class="video-switch">
    <div class="icon-wrapper" @click="toggleCameraList">
      <i class="pi pi-angle-down"/>
      <img v-if="enabled" id="video-selector-icon" alt="video icon" width="24" height="24" src="../../../assets/icons/video-on-icon.svg">
      <img v-else id="video-selector-icon" alt="video icon" width="24" height="24" src="../../../assets/icons/video-off-icon.svg">
      <Menu
        ref="menu"
        id="header-camera-picker"
        :model="videoInputsMenuData"
        :popup="true"
      />
    </div>
    <div v-if="enabled" class="details">
      <span v-text="'Video On'"/>
      <span class="hover-text" v-text="'Turn Off'" @click="toggleVideo"/>
    </div>
    <div v-if="!enabled" class="details">
      <span v-text="'Video Off'"/>
      <span class="hover-text" v-text="'Turn On'"  @click="toggleVideo"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-switch {
  width: 137px;
  height: 40px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .icon-wrapper {
    display: flex;
    cursor: pointer;
    .pi-angle-down {
      align-self: center;
    }
    #video-selector-icon {
      margin-right: 18px;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #555770;
    letter-spacing: 0.2px;
    text-align: left;
    cursor: pointer;
    .hover-text {
      color: #5B8DEF;
    }
  }
}

</style>