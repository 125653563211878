<script lang="ts">

import {
  defineComponent,
  ref,
  watchEffect,
  onMounted,
  inject,
} from 'vue';

import Menu from 'primevue/menu';

import {
  audioOutputs,
  setAudioOutput,
} from '../../../store/session';

export default defineComponent({
  name: 'SpeakerSwitch',
  components: {
    Menu
  },
  setup() {
    const menu = ref(null);
    const audioOutputsMenuData = ref([]);
    // const noBrowserSupport = inject('noBrowserSupport');

    const toggleSpeakerList = toggle => {
      menu.value.toggle(toggle);
    };

    const selectSpeaker = audioOutput => {
      //console.log(audioOutput);
      setAudioOutput(audioOutput);
    };

    watchEffect(() => {
      audioOutputsMenuData.value = [];
      audioOutputs.value?.forEach(audioOutput => {
        audioOutputsMenuData.value.push({label: audioOutput.label, icon: audioOutput.selected ? 'pi pi-check' : null, command: () => selectSpeaker(audioOutput)})
      });
    });

    return {
      menu,
      toggleSpeakerList,
      audioOutputsMenuData,
      // noBrowserSupport
    }
  }
})
</script>

<template>
  <div class="icon-wrapper" @click="toggleSpeakerList">
    <i class="pi pi-angle-down"/>
    <img id="speaker-selector-icon" alt="speaker icon" width="30" height="30" src="../../../assets/icons/speaker.svg">
      <!-- v-if="!noBrowserSupport" -->
    <Menu
      ref="menu"
      id="header-speaker-picker"
      :model="audioOutputsMenuData"
      :popup="true"
    />
  </div>
</template>

<style lang="scss" scoped>
.icon-wrapper {
  height: 40px;
  border-radius: 6px;
  align-items: center;
  background-color: #fff;
  display: flex;
  cursor: pointer;
  .pi-angle-down {
    align-self: center;
  }
  #speaker-selector-icon {
    margin-right: 18px;
  }
}
</style>