<script lang="ts">
import { Stream } from '@opentok/client';
import { defineComponent, ref, toRefs } from 'vue';
import { addStream } from '../../../store/session';
import { userId, clinician } from '../../../store/user';
import {
  removePendingAttendee,
  connectedAttendees,
} from '../../../store/attendees';
import { PathItem } from 'paper/dist/paper-core';

export default defineComponent({
  name: 'PendingAttendee',
  props: {
    stream: {
      type: Stream,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {stream} = toRefs(props);
    const attendeeInfo = ref({streamId: null, connectionId: null, name: null, color: null});
    // const demoNames = ref(['Nico', 'Lucas']);
    // const demoColors = ref(['#73DFE7', '#FEB254']);

    const onApprove = (e) => {
      attendeeInfo.value.streamId = (stream as any).value.id;
      attendeeInfo.value.connectionId = (stream as any).value.connection.connectionId;
      attendeeInfo.value.name = (stream as any).value.name;
      addStream({stream: (stream.value as Stream), attendeeInfo: attendeeInfo.value});
      removePendingAttendee((stream as any).value.streamId);
    };

    /**
     * add the user ID to the attendee object by their Display name
     * @param {attendees} [] connected Attendees array to find userId
     * @returns {userId} string
     */
    const addUserIdByDisplayName = (attendees) => {
      const val = (stream as any).value.name.toLowerCase().trim();
      console.log(userId.value, clinician.value);
      const attendee = attendees.find((item) => {
        console.log(item.userId)
        if (item.userId !== userId.value && clinician.value) {
          console.log(item.userId, userId.value);
          return val === item.displayName.toLowerCase().trim();
        }
      });

      if (attendee) {
        return attendee.userId;
      }
    };

    const onReject = (e) => {
      // rejectAttendee(stream);
    };

    return {
      onApprove,
      onReject,
      stream,
      connectedAttendees,
    };
  },
});
</script>

<template>
  <div class="pending-attendee" :style="{ border: `2px solid green` }">
    <div class="buttons-panel">
      <div class="panel-button approve" @click="onApprove">Approve</div>
      <div class="panel-button reject" @click="onReject">Reject</div>
    </div>
    <div class="name-plate">
      {{ stream.name }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pending-attendee {
  margin: 24px 12px !important;
  width: 186px !important;
  height: 120px !important;
  overflow: hidden !important;
  border-radius: 6px;
  background-image: url('../../../assets/placeholder.png');
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  .buttons-panel {
    // visibility: hidden;
    cursor: pointer;
    // width: 50px;
    // height: 25px;
    background-color: rgba(0, 0, 0, 0.2);
    // position: absolute;
    z-index: 10;
    display: inline-flex;
    .panel-button {
      width: 100%;
      &.approve {
        color: #40c266;
      }
      &.reject {
        color: #fb4541;
      }
    }
  }
  .name-plate {
    margin-top: auto;
    color: #fafafa;
  }
}

// #OT_subscriber_custom {
//   width: calc(100% - 375px) !important;
//   height: calc(100vh - 96px) !important;
//   overflow: hidden !important;
//   position: absolute !important;
//   top: 96px !important;
//   z-index: 1;
//   display: none;
//   &.video {
//     display: block;
//   }
// }
</style>