
import { defineComponent, ref, watch } from 'vue';
import SessionAdapter from '../services/SessionAdapter';
// import ZoomAdapter from '../services/ZoomAdapter';
import OT from '@opentok/client';
import AttendeeDrawer from './AttendeeDrawer';
import FocusView from './FocusView';
// import AccCore from 'opentok-accelerator-core';
// import CallTest from '@/utils/CallTest';
import {
  streams,
  callActive,
  connected,
  publisher,
  setSessionConnected,
  publisherStreamReady
} from '../store/session';
import { clinician } from '../store/user';
import { apiKey } from '../store/env';


export default defineComponent({
  name: 'session',
  components: {
    AttendeeDrawer,
    FocusView,
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    meetingId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const session = new SessionAdapter(apiKey.value, props.sessionId, props.token);
    session.initialize();

    const errorHandler = err => {
      alert(err.message);
    };

    return {
      session,
      streams,
      connected,
      callActive,
      clinician,
      errorHandler
    }
  }
});
