import { AttendeeInfo } from '@/utils/Types';
import { Stream } from '@opentok/client';
import { mutation } from './store';

/**
 * Add an attendee to state exposing their attendee info.
 * An attendee is a user who has pressed join and has been
 * approved by the host to enter the room.
 * Increases the attendee count.
 * @category AttendeeMutations
 * @function addAttendee
 * @param {AttendeeInfo} attendee Attendee to add to state
 */
export const addAttendee = mutation('addAttendee', (state, attendee: AttendeeInfo) => {
  console.log(attendee);
  console.log("ATTENDEE")
  state.attendees[attendee.connectionId] = attendee;
  incAttendeeCount();
});

/**
 * Remove an attendee from state.
 * Decreases the attendee count.
 * @category AttendeeMutations
 * @function removeAttendee
 * @param {string} connectionId Connection id of the attendee to remove from state.
 */
export const removeAttendee = mutation('removeAttendee', (state, connectionId: string) => {
  delete state.attendees[connectionId];
  decAttendeeCount();
});

/**
 * Add a pending attendee stream to state.
 * A pending attendee is a user who has pressed join and
 * is waiting to be approved by the host.
 * Increases pending attendee count.
 * @category AttendeeMutations
 * @function addPendingAttendee
 * @param {Stream} pendingAttendee Stream of pendingAttendee
 */
export const addPendingAttendee = mutation('addPendingAttendee', (state, pendingAttendee: Stream) => {
  state.pendingAttendees.push(pendingAttendee);
  incPendingAttendeeCount();
});

/**
 * Remove pending attendee from state.
 * Decrease pending attendee count.
 * @category AttendeeMutations
 * @function removePendingAttendee
 * @param {string} pendingAttendeeId Stream id of attendee to remove from state.
 */
export const removePendingAttendee = mutation('removePendingAttendee', (state, pendingAttendeeId: string) => {
  const pendingAttendeeToRemoveIndex = state.pendingAttendees.findIndex(el => el.streamId === pendingAttendeeId);
  state.pendingAttendees.splice(pendingAttendeeToRemoveIndex, 1);
  decPendingAttendeeCount();
});

/**
 * Add a connected attendee to state.
 * A connected attendee is a user who is still in the lobby
 * waiting to press join.
 * Increases connected attendee count.
 * @category AttendeeMutations
 * @function addConnectedAttendee
 * @param {}
 */
export const addConnectedAttendee = mutation('addConnectedAttendee', (state, connectedAttendee: any) => {
  // Check if user is already in connected list
  if (state.connectedAttendees.length != 0) {
    const alreadyConnected: boolean = !!state.connectedAttendees.filter(attendee => {
      return attendee.userId == connectedAttendee.userId;
    }).length;
    if (alreadyConnected) return;
  }

  state.connectedAttendees.push(connectedAttendee);
  incConnectedAttendeeCount();
});

export const removeConnectedAttendee = mutation('removeConnectedAttendee', (state, connectedAttendeeId) => {
  const index = state.connectedAttendees.findIndex(el => el.id === connectedAttendeeId);
  state.connectedAttendees.splice(index, 1);
  decConnectedAttendeeCount();
});

export const incAttendeeCount = mutation('incAttendeeCount', state => {
  state.attendeeCount++;
});

export const decAttendeeCount = mutation('decAttendeeCount', state => {
  state.attendeeCount--;
});

export const incPendingAttendeeCount = mutation('incPendingAttendeCount', state => {
  state.pendingAttendeeCount++;
});

export const decPendingAttendeeCount = mutation('decPendingAttendeCount', state => {
  state.pendingAttendeeCount--;
});

export const incConnectedAttendeeCount = mutation('incConnectedAttendeCount', state => {
  state.connectedAttendeeCount++;
});

export const decConnectedAttendeeCount = mutation('decConnectedAttendeCount', state => {
  state.connectedAttendeeCount--;
});