<script lang="ts">
import { defineComponent, toRefs } from 'vue';

import Attendee from './components/Attendee.vue';
import { clinician } from '../../store/user';
import { pendingAttendees, demoAttendees } from '../../store/attendees';
import PendingAttendee from './components/PendingAttendee.vue';
import { connected } from '../../store/session';


export default defineComponent({
  name: 'AttendeeDrawer',
  components: {
    Attendee,
    PendingAttendee
  },
  props: {
    streams: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const {streams} = toRefs(props);
    const demoObjects = demoAttendees.value;

    const errorHandler = err => {
      alert(err.message);
    };
    // watch(publisherStreamReady, (n, o) => {
    //   if (!clinician.value) return;
    //   if (o == false && n) {
    //     const mod = demoObjects.shift();
    //     mod.streamId = publisher.value.stream.connection.connectionId;
    //     addAttendee(mod);
    //   }
    // })

    return {
      streams,
      clinician,
      pendingAttendees,
      connected,
      errorHandler,
    };
}
});
</script>

<template>
<div id="subscribers">
  <template v-if="clinician && pendingAttendees.length >= 1">
    <PendingAttendee v-for="stream in pendingAttendees" :key="stream.id" :stream-id="stream.id" :stream="stream"/>
  </template>
  <Attendee v-for="stream in streams" :key="stream.id" :index="Object.keys(streams).length - 1" :stream-id="stream.id" :stream="stream"></Attendee>
</div>
<div class="publisher-corner">
  <publisher v-if="connected" @error="errorHandler"></publisher>
</div>
</template>

<style lang="scss" scoped>
#subscribers {
  border-top: #ECECEE 1px solid;
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FAFAFC;
}

.publisher-corner {
  // border-top: #ECECEE 1px solid;
  width: 220px;
  height: 50%;
  display: flex;
  flex-direction: column;
  background: #FAFAFC;
  justify-content: flex-end;
}

// #OT_subscriber_custom {
//   width: calc(100% - 375px) !important;
//   height: calc(100vh - 96px) !important;
//   overflow: hidden !important;
//   position: absolute !important;
//   top: 96px !important;
//   z-index: 1;
//   display: none;
//   &.video {
//     display: block;
//   }
// }
</style>