

import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import {
  videoInputs,
  selectedVideoInput,
  setVideoInput
} from '../../../store/session';

import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'CameraPicker',
  components: {
    Dropdown
  },
  setup() {
    const video = ref(null);

    watch(selectedVideoInput, (selectedVideoInput, old) => {
      if (!old) {
        video.value = selectedVideoInput;
      }
    });

    const selectVideo = ({value}) => {
      setVideoInput(value);
    };

    return {
      selectVideo,
      videoInputs,
      video,
    }
  }
})
