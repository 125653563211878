

import { defineComponent, ref } from 'vue';
import BrowserTest from '../../../utils/BrowserTest';


export default defineComponent({
  name: 'BrowserCheck',
  setup() {
    const enabled = ref(false);
    const browserTest = new BrowserTest();

    console.log(browserTest.getBrowser());
    return { enabled }
  }
})
