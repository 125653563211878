
import { Stream } from '@opentok/client';
import { defineComponent, ref, toRefs } from 'vue';
import { addStream } from '../../../store/session';
import { userId, clinician } from '../../../store/user';
import {
  removePendingAttendee,
  connectedAttendees,
} from '../../../store/attendees';
import { PathItem } from 'paper/dist/paper-core';

export default defineComponent({
  name: 'PendingAttendee',
  props: {
    stream: {
      type: Stream,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {stream} = toRefs(props);
    const attendeeInfo = ref({streamId: null, connectionId: null, name: null, color: null});
    // const demoNames = ref(['Nico', 'Lucas']);
    // const demoColors = ref(['#73DFE7', '#FEB254']);

    const onApprove = (e) => {
      attendeeInfo.value.streamId = (stream as any).value.id;
      attendeeInfo.value.connectionId = (stream as any).value.connection.connectionId;
      attendeeInfo.value.name = (stream as any).value.name;
      addStream({stream: (stream.value as Stream), attendeeInfo: attendeeInfo.value});
      removePendingAttendee((stream as any).value.streamId);
    };

    /**
     * add the user ID to the attendee object by their Display name
     * @param {attendees} [] connected Attendees array to find userId
     * @returns {userId} string
     */
    const addUserIdByDisplayName = (attendees) => {
      const val = (stream as any).value.name.toLowerCase().trim();
      console.log(userId.value, clinician.value);
      const attendee = attendees.find((item) => {
        console.log(item.userId)
        if (item.userId !== userId.value && clinician.value) {
          console.log(item.userId, userId.value);
          return val === item.displayName.toLowerCase().trim();
        }
      });

      if (attendee) {
        return attendee.userId;
      }
    };

    const onReject = (e) => {
      // rejectAttendee(stream);
    };

    return {
      onApprove,
      onReject,
      stream,
      connectedAttendees,
    };
  },
});
