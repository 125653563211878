<script lang="ts">

import {
  defineComponent,
  ref,
} from 'vue';

import { setScreenShareActive } from '../../../store/views';

export default defineComponent({
  name: 'ScreenShareSwitch',
  setup() {
    const enabled = ref(false);

    const startScreenShare = () => {
      enabled.value = !enabled.value;
      setScreenShareActive(enabled.value);
    };

    return {
      enabled,
      startScreenShare,
    }
  }
})
</script>

<template>
  <div id="screen-share-switch">
    <div v-if="enabled" class="details">
      <span v-text="'Sharing Screen'"/>
      <span class="hover-text" v-text="'Cancel Screen Share'" @click="startScreenShare"/>
    </div>
    <div v-if="!enabled" class="details">
      <span v-text="'Screen Share Off'"/>
      <span class="hover-text" v-text="'Share Screen'"  @click="startScreenShare"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#screen-share-switch {
  width: 137px;
  height: 40px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .details {
    display: flex;
    flex-direction: column;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #555770;
    letter-spacing: 0.2px;
    text-align: left;
    cursor: pointer;
    .hover-text {
      color: #5B8DEF;
    }
  }
}
</style>