import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }

import { ref } from 'vue';
import { clearUserHistory } from '../../../store/whiteboard/actions';


export default _defineComponent({
  props: {
  id: String,
  close: Function
},
  setup(__props) {

const props = __props
const showCloseButton = ref(false);



const clearHistory = () => {
  clearUserHistory(props.id);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "user-menu",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (showCloseButton.value = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showcloseButton = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "p-button pull-right",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (__props.close && __props.close(...args)))
      }, "X")
    ]),
    _createElementVNode("div", { class: "menu-buttons" }, [
      _createElementVNode("button", {
        class: "p-button",
        onClick: clearHistory
      }, "Clear Whiteboard")
    ])
  ], 32))
}
}

})