import STATE from './state';
import { SessionState } from '@/utils/Types';

import {
    createStore
} from '@harlem/core';

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore<SessionState>('session', STATE);