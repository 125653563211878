import {
  setFocusViewElement,
  setFocusViewInfo
} from '../views';

export async function applyAgendaUpdate(data) {
  console.log(data);
  const eventData = JSON.parse(data.data);
  // setFocusViewInfo(eventData.view);
  setFocusViewElement({view: eventData.view, data: eventData.data});
  console.log(eventData);
}