import { WhiteboardColors, WhiteboardState } from '../../utils/Types';

export const STATE: WhiteboardState = {
  canvas: null,
  whiteboardPaper: null,
  history: [],
  pathStack: [],
  textStack: [],
  eraseStack: [],
  batchUpdates: [],
  erasing: false,
  textToggled: false,
  colors: [
    WhiteboardColors.BLACK,
    WhiteboardColors.BLUE,
    WhiteboardColors.RED,
    WhiteboardColors.GREEN,
    WhiteboardColors.ORANGE,
    WhiteboardColors.PURPLE,
    WhiteboardColors.BROWN,
  ],
  color: WhiteboardColors.BLACK,
  client: {
    dragging: false,
    lastX: 0,
    lastY: 0,
    uuid: null,
  },
};