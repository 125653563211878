

import { defineComponent } from 'vue';

import Card from 'primevue/card';
import CameraPicker from './components/CameraPicker.vue';
import MicPicker from './components/MicPicker.vue';
import SpeakerPicker from './components/SpeakerPicker.vue';
import BrowserCheck from './components/BrowserCheck.vue';
// import BandwidthCheck from './components/BandwidthCheck.vue';

export default defineComponent({
  name: 'CallPreview',
  components: {
    Card,
    CameraPicker,
    MicPicker,
    SpeakerPicker,
    BrowserCheck,
    // BandwidthCheck,
  },
})
