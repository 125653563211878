import { getter } from './store';

export const focusViewElement = getter('focusViewElement', state => state.focusViewElement);

export const focusViewInfo = getter('focusViewInfo', state => state.focusViewInfo);

export const whiteboardEnabled = getter('whiteboardEnabled', state => state.whiteboardEnabled);

export const hostMenuOpen = getter('hostMenuOpen', state => state.hostMenuOpen);

export const hostMenuSelection = getter('hostMenuSelection', state => state.hostMenuSelection);

export const videoStatus = getter('videoStatus', state => state.videoStatus);

export const screenShareActive = getter('screenShareActive', state => state.screenShareActive); 