<script lang="ts">
import { Stream } from '@opentok/client';
import { defineComponent, onMounted, ref, toRefs, watch } from 'vue';
import SubscriberAdapter from '../../../services/SubscriberAdapter';
import { selectedAudioOutput } from '../../../store/session';
import { clearUserHistory } from '../../../store/whiteboard';
import { clinician } from '../../../store/user';
import UserMenu from './UserMenu.vue';

export default defineComponent({
  name: 'Attendee',
  props: {
    stream: {
      type: Stream,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    UserMenu,
  },
  setup(props) {
    const root = ref(null);
    const { stream, streamId, index } = toRefs(props);
    const idString = ref('OT_subscriber');
    const showButtons = ref(false);
    const showUserMenu = ref(false);
    const focusView = ref(false);
    const subscriberAdapter = new SubscriberAdapter();
    const demoNames = ref(['Nico', 'Lucas']);
    const connectionId = ref((stream.value as Stream).connection.connectionId);

    onMounted(() => {
      subscriberAdapter.subscribe(
        connectionId.value,
        root.value,
        {
          insertMode: 'append',
          height: '100%',
          width: '100%',
          showControls: false,
        },
        streamId.value as string
      );
    });

    watch(selectedAudioOutput, () => {
      subscriberAdapter.attachAudioOutputToVideo();
    });

    const onFullscreen = (e) => {
      if (!focusView.value) {
        document.querySelector('#main_focus_viewer').append(root.value);
        focusView.value = true;
      } else {
        document.querySelector('#subscribers').append(root.value);
        focusView.value = false;
      }
    };

    const setShowUserMenu = (e) => {
      showButtons.value = false;
      showUserMenu.value = true;
    };

    const setHideUserMenu = (e) => {
      console.log("HIDE")
      showUserMenu.value = false;
    };

    return {
      root,
      connectionId,
      idString,
      clinician,
      showButtons,
      showUserMenu,
      setShowUserMenu,
      setHideUserMenu,
      onFullscreen,
      focusView,
      demoNames,
      index,
      stream,
    };
  },
});
</script>

<template>
  <div
    :id="idString"
    @mouseover="showButtons = !showUserMenu ? true : false"
    @mouseleave="showButtons = false"
    class="OT_subscriber"
    :class="{ clinician, focused: focusView }"
    ref="root"
  >
    <div class="buttons-panel" :class="{ show: showButtons }">
      <div class="panel-button full-screen-button" @click="onFullscreen">
        <img src="../../../assets/icons/fullscreen.svg" />
      </div>
      <div class="panel-button options-button" @click="setShowUserMenu">
        <img src="../../../assets/icons/three-dots.svg" />
      </div>
    </div>
    <div v-if="!showUserMenu" class="name-plate">{{ stream.name }}</div>
    <UserMenu v-if="showUserMenu" :id="connectionId" :close="setHideUserMenu"/>
  </div>
</template>

<style lang="scss" scoped>
.OT_subscriber {
  margin: 24px 12px !important;
  width: 186px !important;
  height: 120px !important;
  overflow: hidden !important;
  border-radius: 6px;
  border: 2px solid #73dfe7;
  &.focused {
    height: 100% !important;
    width: 100% !important;
  }

  .buttons-panel {
    visibility: hidden;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 10;
    display: inline-flex;
    &.show {
      visibility: initial;
    }
    .panel-button {
      width: 100%;
      img {
        vertical-align: middle;
      }
    }
  }
  .name-plate {
    position: absolute;
    bottom: 0;
    z-index: 3;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
  }
}
</style>