import { getter } from './store';

export const history = getter('history', state => state.history);

export const canvas = getter('canvas', state => state.canvas);

export const whiteboardPaper = getter('whiteboardPaper', state => state.whiteboardPaper);

export const pathStack = getter('pathStack', state => state.pathStack);

export const batchUpdates = getter('batchUpdates', state => state.batchUpdates);

export const erasing = getter('erasing', state => state.erasing);

export const textToggled = getter('textToggled', state => state.textToggled);

export const colors = getter('colors', state => state.colors);

export const color = getter('color', state => state.color);

export const client = getter('client', state => state.client);
